<template>
<div>
  <no-stripe-card-modal ref="noStripeCardModalRef"></no-stripe-card-modal>

  <div class="explain-card limited-width-box">
    <div>
      <i class="material-icons">info</i>
      <div class="flex-grow-1" style="padding-top: 3px;">
        <div class="explain-title">API add-on pricing</div>
        <div class="explain-text mt-3">
          Each month you pay just $10 to get access to POP API and run POP API calls using your POP Credits balance. 1 API call will equal to 1 POP run and will use 2 POP Credits from your balance.<br />
          Access API documentation
          <a href="https://api-docs.pageoptimizer.pro/" target="_blank"
            class="profileAnchorColor--text text-decoration-underline">here</a>.
        </div>
      </div>
    </div>
  </div>
  <div class="mt-10">
    <div class="explain-title">API add-on pricing</div>    
    
    <div class="price-packages mt-5">
      <div class="mt-5 white-common-box pa-5" style="min-width: 280px;">
        <div class="price-label mt-5">$10/month</div>
        <div class="flex-grow-1">
          <div class="mt-7 buy-this-package font-weight-bold">Best for individual site owners</div>
          <div class="mt-7 buy-this-package">
            Buy this package for $10 per month and run POP API calls using your POP Credits balance.
          </div>

          <ul class="mt-10">
            <li v-for="(item, idx) in featuresList" :key="idx" class="mt-1">
              <span>{{ item.text }}</span>
            </li>
          </ul>
        </div>

        <div class="mt-7">
          <v-btn block rounded
            :color="activeAddOnPurchase && !activeAddOnPurchase.cancelledOn ? 'green7Color' : 'profileAnchorColor'"
            class="text-none whiteColor--text"
            @click="purchaseAction">
            <template v-if="activeAddOnPurchase && !activeAddOnPurchase.cancelledOn">
              <span class="material-icons">check</span>
              <span>Your current package</span>
            </template>
            <template v-else>
              <span>Buy package</span>
            </template>
          </v-btn>
        </div>

        <div class="mt-5 text-center" v-if="activeAddOnPurchase && !activeAddOnPurchase.cancelledOn">
          <a class="cancel-btn" @click="() => { showCancelConfirmationModal = true; }">
            Cancel your add-on subscription
          </a>
        </div>

        <div class="mt-5 grayColor--text" v-if="activeAddOnPurchase && activeAddOnPurchase.cancelledOn">
          You cancelled on {{ toLocalDateTimeString(activeAddOnPurchase.cancelledOn) }}
        </div>

      </div>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showCheckoutModal}">
    <div class="modal-background"></div>
    <div class="modal-content" style="width: 900px; max-width: 95vw;">
      <div class="box modal-container" style="overflow: hidden; padding: 0;">
        <div class="checkout-modal">
          <div class="d-flex pad25">
            <div class="modal-title">Checkout</div>
          </div>
          <div class="pad25 main-part">
            <div class="card-part">
              <div class="sub-title mb-5" style="min-height: 30px;">Card details</div>
              <div v-if="preferredCard" class="preferred-card-info">
               <div>
                <template v-if="preferredCard.brand == 'visa'">
                  <img src="/static/visa.png" width="40px" />
                </template>
                <template v-else-if="preferredCard.brand == 'mastercard'">
                  <img src="/static/mastercard.png" width="40px" />
                </template>
                <template v-else>
                  {{ preferredCard.brand }}
                </template>
               </div>
               <div class="flex-grow-1 card-text">
                ending in {{ preferredCard.last4 || '' }}
               </div>
               <div>
                <a @click="onUseDifferentCard">Edit credit card</a>
               </div>
              </div>
            </div>
            <div class="summary-part">
              <div class="d-flex align-center mb-5" style="min-height: 30px;">
                <div class="sub-title">
                  Summary
                </div>
                <!-- <div class="flex-grow-1 text-right">
                  <template v-if="showApplyCouponBox">
                    <div class="promo-input-in-popup">
                      <input type="text" placeholder="coupon code" v-model="promoCode">
                      <a class="coupon-apply-btn" @click="applyCoupon">
                        Apply
                      </a>
                    </div>
                  </template>
                  <template v-else>
                    <a @click="showApplyCouponBox = true;" class="show-apply-coupon-button">
                      <span class="material-icons-outlined">add</span>
                      Apply coupon code
                    </a>
                  </template>
                </div> -->
              </div>
              <!-- <p v-if="serviceCouponsMessage && serviceCouponsValid" class="help has-text-success">{{ serviceCouponsMessage }}</p>
              <p v-if="serviceCouponsMessage && !serviceCouponsValid" class="help has-text-danger">{{ serviceCouponsMessage }}</p> -->
              <div class="summary-box pb-5">
                <div class="d-flex align-center">
                  <div class="flex-grow-1">
                    <div>Product</div>
                    <div class="font-weight-bold" v-html="'Pageoptimzier API add-on package'">
                    </div>
                  </div>
                  <div class="nowrap">
                    $ {{ credits }}
                  </div>
                </div>
                
                <div class="mt-5 pt-10 top-border">
                  <div class="d-flex align-start">
                    <v-checkbox hide-details="auto" class="mt-0 pt-0" v-model="agreedTermsAndService"></v-checkbox>
                    <span>
                      By accessing or calling the API, you agree to abide by all the PageOptimizer Pro
                      <a href="https://www.pageoptimizer.pro/terms-of-service" target="_blank" class="terms-of-service-link">Terms of Service.</a>
                    </span>
                  </div>
                  <div class="buttons-area mt-5">
                    <v-btn rounded
                      :disabled="!agreedTermsAndService"
                      color="profileAnchorColor"
                      class="button-with-icon whiteColor--text text-none"
                      @click="() => {
                        confirmedUseExistingPayment = true;
                        showCheckoutModal = false;
                        submit();
                      }">
                      <span>Subscribe</span>
                    </v-btn>
                    <a class="cancel-link" @click="showCheckoutModal = false;">
                      Cancel
                    </a>
                  </div>
                </div>
                <div class="mt-5">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="showCheckoutModal = false"><i class="material-icons">close</i></button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showPurchaseCompleteModal}">
    <div class="modal-background" @click.stop="showPurchaseCompleteModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Purchase Complete</p>
          <p class="subtitle-font mainColor--text">
            Successfully purchased the add-on package.
          </p>
          <div class="d-flex justify-end gap10">
            <v-btn rounded color="profileAnchorColor" class="button-with-icon whiteColor--text text-none"
              @click.stop="() => {
                showPurchaseCompleteModal = false;
                $router.push({
                  path: `/useraccount/${user.id}`,
                  query: {
                    mainTab: `manage-pop-api-key`
                  }
                })
              }">
              <span>Go to API key management</span>
            </v-btn>
            <v-btn rounded color="grayColor" class="button-with-icon mainColor--text text-none"
              @click.stop="() => {
                showPurchaseCompleteModal = false;
              }">
              <span>Close</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showPurchaseCompleteModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showCancelConfirmationModal}">
    <div class="modal-background" @click.stop="showCancelConfirmationModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text">Cancel confirmation</p>
          <p class="subtitle-font mainColor--text">
            Do you want to cancel API add-on package?            
          </p>
          <div class="d-flex justify-end gap10">
            <v-btn rounded color="profileAnchorColor" class="button-with-icon whiteColor--text text-none"
              @click.stop="() => {
                showCancelConfirmationModal = false;
                cancelAddonAction()
              }">
              <span>Yes</span>
            </v-btn>
            <v-btn rounded color="grayColor" class="button-with-icon mainColor--text text-none"
              @click.stop="() => {
                showCancelConfirmationModal = false;
              }">
              <span>Close</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showCancelConfirmationModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="modal" :class="{'is-active': showCancelledAddonSubscriptionModal}">
    <div class="modal-background" @click.stop="showCancelledAddonSubscriptionModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title-font is-size-4 mainColor--text text-center">Subscription cancelled</p>
          <p class="subtitle-font mainColor--text text-center">
            Your API add-on package cancellation has been processed successfully.
          </p>
          <div class="d-flex justify-end gap10">
            <v-btn rounded color="profileAnchorColor" class="button-with-icon whiteColor--text text-none"
              @click.stop="() => {
                showCancelledAddonSubscriptionModal = false;
              }">
              <span>Ok</span>
            </v-btn>
          </div>
        </div>
      </div>
      <button
        class="custom-modal-close-btn"
        aria-label="close"
        @click="() => (showCancelledAddonSubscriptionModal = false)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  


  <div class="modal" :class="{'is-active': showErrorModal}">
    <div class="modal-background" @click.stop="showErrorModal = false"></div>
    <div class="modal-content">
      <div class="box modal-container">
        <div class="modal-content-root">
          <p class="title">Purchase Failed</p>
          <p class="subtitle">{{ errorMsg }}</p>
          <div class="text-right">
            <v-btn rounded color="profileAnchorColor" class="button-with-icon mainColor--text text-none"
              @click="showErrorModal = false">
              <span>Ok</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click.stop="showErrorModal = false"></button>
  </div>

</div>
</template>

<script>
import NoStripeCardModal from "@/components/NoStripeCardModal.vue";
import {  segmentTrack } from '@/utils'
import servicePackages from '@/components/service_package_constants'
import { toLocalDateTimeString } from '@/utils'

export default {
  components: {
    NoStripeCardModal
  },
  data () {
    return {
      agreedTermsAndService: false,
      showCheckoutModal: false,
      preferredCard: '',
      confirmedUseExistingPayment: false,
      promoCode: '',

      errorMsg: '',
      showErrorModal: false,

      featuresList: [
        {
          text: 'Access to POP API for $10/mo',
        },
        {
          text: 'Run API through your plan credits',
        },
        {
          text: '1 API call equals 1 POP run',
        },
      ],

      showPurchaseCompleteModal: false,
      showCancelConfirmationModal: false,
      showCancelledAddonSubscriptionModal: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    activeAddOnPurchase () {
      return this.user.activeAddOnPurchase;

    },
    addOnServicePackage() {
      return servicePackages['ADD_ON_PKG_A']
    },
    selectedServicePkg() {
      if (this.addOnServicePackage) {
        return this.addOnServicePackage.code
      } else {
        return ''
      }
    },
    serviceDescription() {
      return this.addOnServicePackage ? this.addOnServicePackage.description : '';
    },
    credits() {
      return this.addOnServicePackage ? this.addOnServicePackage.price : 0;
    }
  },
  methods: {
    toLocalDateTimeString (d) {
      return toLocalDateTimeString(d)
    },
    loadUser () {
      return this.$store.dispatch('loadUser')
              .then((response) => {
                this.$store.commit('setUser', response.data.user)
                this.$store.dispatch('loadUserHasPaymentSources').then(
                  response => {
                    if (response.data && response.data.status === "SUCCESS") {
                      this.$store.commit('setUserHasPaymentSources', response.data.hasPaymentSource);
                    }
                  }
                )
              })
    },
    purchaseAction () {
      if (this.activeAddOnPurchase) {
        return;
      }
      this.showCheckoutModal = true;
    },
    submit() {
      const order = {
        description: this.serviceDescription,
        servicePackage: this.selectedServicePkg,
        amount: this.credits
      }
      if (this.credits < 10) {
        return
      }
      let promise
      if (this.user.hasPaymentSource && !this.useDifferentCard) {
        if (!this.confirmedUseExistingPayment) {
          this.showConfirmUseExistingPayment = true
          return;
        }
        this.$store.commit('showLoading')
        promise = this.$store.dispatch('createPurchase', {
          userId: this.user.id,
          servicePackage: this.selectedServicePkg,
          stripeToken: '',
          paymentService: 'stripe',
          description: order.description,
          promoCode: this.promoCode.trim()
        })
      } else {
        this.$refs.noStripeCardModalRef.showModalAction();
        return;
      }
      promise.then((response) => {
        if (response.data.status === 'FAILURE') {
          if (response.data.clientSecret) {
            window.stripe.confirmCardPayment(response.data.clientSecret).then((result) => {
              if (result.error) {
                // Inform the customer that there was an error.
                this.errorMsg = result.error.message
                this.showErrorModal = true
              }
            })
          } else {
            this.errorMsg = response.data.msg
            this.showErrorModal = true
          }
        } else if (response.data.status === 'SUCCESS') {
          this.showPurchaseCompleteModal = true
          if (response.data.user) {
            this.$store.commit('setUser', response.data.user)
          }
          segmentTrack('purchase', {
            paymentService: 'stripe',
            email: response.data.user.email,
            userID: response.data.user.id,
            servicePackage: this.selectedServicePkg,
            promoCode: this.promoCode.trim()
          })
        } else  if (response.data.status === 'CLOSED') {
          this.errorMsg = response.data.errorMsg
        }
        return 1
      })
        .catch((err) => {
          this.errorMsg = "Transaction denied"
          console.log('stripe error', err)
        })
        .finally(() => {
          setTimeout(() => {
            this.loadUser().finally(() => {
              this.$store.commit('hideLoading')
            })            
          }, 1000);
          
        })
    },
    onUseDifferentCard () {
      this.$router.push(`/useraccount/${this.user.id}?mainTab=billing-and-payments&subTab=credit-cards`)
    },
    cancelAddonAction () {
      this.$store.commit('showLoading')
      this.$store.dispatch("cancelSubscription", {
        purchaseId: this.activeAddOnPurchase.id,
      }).then(response => {
        if (response.data && response.data.status === "SUCCESS") {
          if (response.data.user) {
            this.$store.commit("setUser", response.data.user);
          }
          this.loadUser().finally(() => {
            this.showCancelledAddonSubscriptionModal = true;
          })

        } else {
          this.$notify({
            group: 'info', type: 'error', duration: 5000,
            text: 'Unable to cancel at this time. If you have already cancelled your PayPal payment,  your POP subscription has been cancelled.\nYour dashboard will update in the next 24 hours. If you have not cancelled in PayPal and are still receiving this error message please contact support.',
          })
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      });
    }
  },
  mounted () {
    this.$store.commit('showLoading')
    return this.$store.dispatch('loadUser').then((response) => {
        if (response.data && response.data.status === 'SUCCESS') {
            let user = response.data.user
            this.$store.commit('setUser', user)
            this.$store.dispatch('loadUserHasPaymentSources').then(
              response => {
                if (response.data && response.data.status === "SUCCESS") {
                  this.$store.commit('setUserHasPaymentSources', response.data.hasPaymentSource);
                  if (response.data.hasPaymentSource) {
                    this.$store.dispatch('fetchPaymentSources').then((response) => {
                      if (response.data.status == 'SUCCESS') {
                        const preferredCard = response.data.cards.find(card => card.preferred)
                        this.preferredCard = preferredCard
                      } else {
                        console.log('error fetching cards')
                      }
                    })
                  }
                }
              }
            )
        }
      }).finally(() => this.$store.commit('hideLoading'))
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/ui-for-api-style.scss";
</style>