<template>
  <div>
    <page-opportunity-form
      :siteId="siteId"
      @setCalculating="(d) => { isCalculating = d }"
    ></page-opportunity-form>

    <div v-if="isLoadingHistory" class="mt-10">
      Loading last saved data...
    </div>
    <div v-else-if="Boolean(config.wizardPOResultsHistoryMetadata)" class="mt-10">
      Showing last saved data ({{ config.wizardPOResultsHistoryMetadata.createdAt }})
    </div>

    <page-opportunity-results
      :isCalculating="isCalculating"
      :resultData="config.wizardPOResults"
    ></page-opportunity-results>
  
  </div>
  </template>
  
  
  <script>
  import PageOpportunityForm from './PageOpportunityForm.vue';
  import PageOpportunityResults  from './PageOpportunityResults.vue';
  import { chain } from 'lodash'
  
  export default {
    props: {
      campaignId: {
        default: 0,
      },
      siteId: {
        default: 0,
      }
    },
    components: {
      PageOpportunityForm,
      PageOpportunityResults,
    },
    computed: {
      campaign () {
        return this.$store.state.currentCampaign
      },
      config() {
        return this.$store.getters.insightKeywordsConfig;
      },
    },
    watch: {
      isCalculating: function (newVal, oldVal) {
        if (oldVal != newVal && newVal) {
          // when wizard starts calculating, reset history meta data
          this.setConfig('wizardPOResultsHistoryMetadata', null)
        }
      },
    },
    data () {
      return {
        isCalculating: false,
        isLoadingHistory: false,
      }
    },
    methods: {
      setConfig(key, value) {
        this.$store.commit("setInsightKeywordsConfig", { key, value });
      },
      loadHistory ({silent = false}) {
        if (!silent) {
          this.isLoadingHistory = true
          this.setConfig('wizardPOResults', null)
          this.setConfig('wizardPOResultsHistoryMetadata', null)
        }

        let data = {
          campaignId: this.campaignId,
          tabType: 'PAGE_OPPORTUNITY'
        }
        return this.$store.dispatch('loadRecentKeywordWizardData', data)
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            if (response.data.wizardData) {
              // put stored result in component state
              this.resultData = response.data.wizardData.result;
              const pageUrls = chain(this.resultData).map('page').uniq().value();
              this.setConfig('wizardPOPageUrls', pageUrls);
              this.setConfig('wizardPOResults', response.data.wizardData.result)

              // also put history meta data in component state
              const {result: _, ...historyMetaData} = response.data.wizardData
              this.setConfig('wizardPOResultsHistoryMetadata', historyMetaData)
            }

            if (this.$store.state?.siteContentAuditsBySiteId?.[this.siteId]) {
              return this.$store.dispatch('getActiveSiteContentAudit', {
                siteId: this.siteId,
              })
                .then(response => {
                  if (response.data && response.data.status == 'SUCCESS') {
                    this.$store.commit('setSiteContentAuditBySiteId', {siteId: this.siteId, audit: response.data.data})
                    return Promise.resolve()
                  }
                })
            }
          }
        }).finally(() => {
          this.isLoadingHistory = false
        })
      },
    },
    mounted () {
      // ON MOUNTED
      // - try loading data from backend (but only if global state is empty)
      (
        this.config.wizardPOResultsHistoryMetadata
          ?Promise.resolve()
          :this.loadHistory({})
      )
    },
  }
  </script>
  
  
  <style scoped lang="scss">
  
  </style>
