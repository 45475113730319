<template>
<div>
  <div class="title-label d-flex align-center gap10">
    Keyword Research log
  </div>
  <div class="mt-5 d-flex align-center">
    <div class="flex-grow-1">
      <date-picker v-model="dates" range></date-picker>
      <v-btn rounded outlined
        color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none ml-2"
        @click="loadData"
        :loading="isLoading">
        <span>Load data</span>
      </v-btn>
    </div>
    <div class="total-credits-in-range">
      <span>
        Total credits:&nbsp;
      </span>
      <span>{{ totalCreditsUsed }}</span>
      <v-tooltip top color="transparent">
        <template v-slot:activator="{ on, attrs }">
          <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
        </template>
        <div class="white-bg-tooltip small-tooltip">
          Total credits used is the sum of all credits used in Keyword Research tools for the selected dates.
        </div>
      </v-tooltip>
    </div>
  </div>

  <v-card class="is-fullheight flex flex-column white-common-box mt-5">
    <div class="hscroll-container">
      <div v-if="!records?.length" class="p-4">
        No results for the selected period
      </div>
      <b-table
        v-else
        :data="records"
        :loading="isLoading"
        :paginated="totalRows > perPage"
        :total="totalRows"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange"
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
      >
        <template slot-scope="props">
          <b-table-column field="rawCreatedAt" label="rawCreatedAt" :visible="false">
            {{ props.row.createdAt }}
          </b-table-column>
          <b-table-column field="createdAt" label="Date">
            {{ toLocalDateString(props.row.createdAt) }}
          </b-table-column>
          <b-table-column field="id" label="Report Id" sortable class="text-left">
            {{ props.row.record_id }}
          </b-table-column>
          <b-table-column field="type" label="Report type">
            {{ getReportLabel(props.row.type) }}
          </b-table-column>
          <b-table-column field="Credits used" label="Credits used">
            <router-link v-if="getReportURL(props.row)" :to="getReportURL(props.row)" style="color: var(--v-link15Color-base)">
              {{ props.row.creditsUsed }}  {{ props.row.creditsUsed < 0 ?'(Refund)':'' }}
            </router-link>
            <template v-else>
              {{ props.row.creditsUsed }}  {{ props.row.creditsUsed < 0 ?'(Refund)':'' }}
            </template>
          </b-table-column>
        </template>
      </b-table>
      <!-- <table class="top-border-table is-fullwidth invoice-table" style="min-width: 1000px">
        <thead>
          <tr>
            <th>Report ID</th>
            <th>Keyword</th>
            <th>Date</th>
            <th>Report credits</th>
            <th>EEAT credits</th>
            <th>NLP credits</th>
            <th>Watchdog credits used</th>
            <th>AI article count</th>
            <th>New AI content credits</th>
            <th>Free AI regenerations</th>
            <th>Paid AI regenerations</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(r, idx) in records">
            <tr :key="idx">
              <td>{{ r.id }}</td>
              <td>{{ r.keyword }}</td>
              <td>{{ toLocalDateString(r.createdAt) }}</td>
              <td>{{ r.reportCreditsUsed == 0 ? '-' : r.reportCreditsUsed }}</td>
              <td>{{ r.eatCredits == 0 ? '-' : r.eatCredits }}</td>
              <td>{{ r.nlpCredits == 0 ? '-' : r.nlpCredits }}</td>
              <td>{{ r.watchdogCredits == 0 ? '-' : r.watchdogCredits }}</td>
              <td>{{ r.aiArticleCount }}</td>
              <td>{{ r.aiArticleCreditsTotal }}</td>
              <td>{{ r.aiFreeRegenerationUsedTotal }}</td>
              <td>{{ r.aiPaidRegenerationUsedTotal }}</td>
            </tr>
          </template>
        </tbody>
      </table> -->
    </div>
  </v-card>
</div>
</template>

<script>

import moment from 'moment'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { toLocalDateString } from "@/utils";


export default {
  props: {
    forAdminPage: {
      type: Boolean,
      default: false,
    },
    userId: {
      default: 0,
      type: Number,
    }
  },

  components: {
    DatePicker,
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  data () {
    return {
      dates: [moment().subtract(1, 'months').toDate(), moment().toDate()],


      isLoading: false,
      totalRows: 0,
      records: [],
      totalCreditsUsed: 0,

      currentPage: 1,
      perPage: 10, // no pagination for now for the total credits
      sortField: "rawCreatedAt",
      sortOrder: "asc",
      defaultSortOrder: "asc",
      searchText: "",

    }
  },
  methods: {
    test(data) {
      console.log(data)
    },

    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },

    onPerPageChange() {
      // this.$nextTick(() => this.loadData());
    },
    onPageChange(page) {
      this.currentPage = page;
      // this.loadData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // this.loadData();
    },
    onFilter() {
      // console.log('filtering')
      // this.$nextTick(() => {
      //   this.loadData();
      // });
    },

    getReportLabel(type) {
      return {
        'KeywordResearch.task': 'Keyword Insight > Get keyword data',
        'KeywordResearch.task.advanced': 'Keyword Insight > Get keyword data (advanced)',
        'SupportKeywordResearch.task.0': 'Keyword Strategy > Existing Content > Supporting Keywords Research > Search keywords',
        'SupportKeywordResearch.task.1': 'Keyword Strategy > Existing Content > Supporting Keywords Research > Get keyword data',
        'SupportKeywordResearch.task.1.advanced': 'Keyword Strategy > Existing Content > Supporting Keywords Research > Get keyword data (advanced)',
        'TopLevelSupportKeywordResearch.task.0': 'Keyword Strategy > New Content > Search keywords',
        'TopLevelSupportKeywordResearch.task.1': 'Keyword Strategy > New Content > Get keyword data',
        'TopLevelSupportKeywordResearch.task.1.advanced': 'Keyword Strategy > New Content > Get keyword data (advanced)',
      }[type] || type
    },

    getReportURL(row) {
      const siteId = row?.record_metadata?.site_id;
      const campaignId = row?.record_metadata?.campaign_id;

      if (siteId && campaignId) {

        if (row.type === 'KeywordResearch.task') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-insight`;
        }
        if (row.type === 'KeywordResearch.task.advanced') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-insight`;
        }
        if (row.type === 'SupportKeywordResearch.task.0') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/supporting-keyword-research`;
        }
        if (row.type === 'SupportKeywordResearch.task.1') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/supporting-keyword-research`;
        }
        if (row.type === 'SupportKeywordResearch.task.1.advanced') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/supporting-keyword-research`;
        }
        if (row.type === 'TopLevelSupportKeywordResearch.task.0') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-wizard/new-content/keyword-only`;
        }
        if (row.type === 'TopLevelSupportKeywordResearch.task.1') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-wizard/new-content/keyword-only`;
        }
        if (row.type === 'TopLevelSupportKeywordResearch.task.1.advanced') {
          return `/sites/${siteId}/campaign/${campaignId}/keyword-wizard/new-content/keyword-only`;
        }

      }

      return undefined

    },

    loadData() {
      let startDate = moment(this.dates[0]).startOf('day').toDate();
      let endDate = moment(this.dates[1]).endOf('day').toDate();

      if (this.isLoading) return;
      this.isLoading = true;
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`,
        `startDate=${startDate.getTime()}`,
        `endDate=${endDate.getTime()}`,
        `userId=${this.forAdminPage ? this.userId : 0}`
      ].join("&");

      this.$store.dispatch('getKeywordResearchCreditUsageForUser', { params })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log(response.data)
          this.totalRows = response.data.total;
          this.records = response.data.records;
          this.totalCreditsUsed = response.data.totalCredits
        } else {
          this.totalRows = 0;
          this.records = [];
          this.totalCreditsUsed = 0
          
          if (response?.data?.msg) {
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg
            })
          }

        }
      })
      .finally(() => {
        this.isLoading = false;
      })
    },

  },
  mounted () {
    this.loadData()
  }

}
</script>

<style lang="scss" scoped>

.title-label {
    font-size: 18px;
    font-weight: bold;
  }

.total-credits-in-range { 
  display: flex;
  align-items: center;
  span {
    font-size: 17px;
  }
  & > span:nth-child(1) {
    font-weight: bold;
  }
}


.b-table {
  .table thead td, .table thead th {
    border-bottom-width: 1px !important;
  }
  th, td {
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.linked-number {
  color: var(--v-link15Color-base);
}
</style>