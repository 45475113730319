
import axios from 'axios'
import store from '@/store'
import router from '@/router'

const DEMO_ALLOWED_URLS = [
  ['get', '/demo_user_token/'],
  ['get', '/users/'],
  ['get', '/google-search-locations/'],
  ['get', '/sites/'],
  ['get', '/sites/pages/pageruns/'],
  ['post', '/content-brief/note/all/'],
  ['post', '/checklist/get_all_tasks/'],
  ['get', '/proxies/'],
  ['post', '/fetch-page-run-by-secret-key-or-id/'],
  ['post', '/get-ai-run/'],
  ['post', '/get-checklist-for-extension/'],
]

const requestIntercepter = (config) => {
  const sanitizedUrl = config.url
    .replace(API_URL, '')
    .replace(/\?.+/, '')
    .replace(/\d+/g, '')
    .replace(/\/\//g, '/');
  if (store.state.isDemoUser) {
    return DEMO_ALLOWED_URLS.some(
      ([method, url]) => method === config.method && url === sanitizedUrl
    )
    ? config
    : Promise.reject('Request not allowed');
  } else {
    return config
  }
}

const mainAxios = axios.create();
mainAxios.interceptors.response.use((response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.log('error in http response ', error.response)
      store.commit('clearStore')
      if (error.response.data.message) {
        store.commit('setGlobalErrorMsg', { msg: error.response.data.message })
      }
      // We don't want to navigate to /login if we are already there
      if (router.currentRoute.path !== '/login') {
        router.push('/login');
      }
    }
    return Promise.reject(error)
  })
mainAxios.interceptors.request.use(requestIntercepter)

const defaultAxios = axios.create();
defaultAxios.interceptors.response.use((response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.log('error in http response ', error.response)
      store.commit('clearStore')
      if (error.response.data.message) {
        store.commit('setGlobalErrorMsg', { msg: error.response.data.message })
      }
    }
    return Promise.reject(error)
  })
defaultAxios.interceptors.request.use(requestIntercepter)

function authHeader (jwt) {
  return { Authorization: `Bearer: ${jwt}` }
}

export function authenticate (userData) {
  return mainAxios.post(`${API_URL}/login/`, userData)
}

export function authenticateFromExtension (userData) {
  return defaultAxios.post(`${API_URL}/login/`, userData)
}

export function change2FaAuthSetting(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/2fa-auth-setting/`, data, { headers })
}

export function getDemoUserToken ({ email }) {
  return mainAxios.get(`${API_URL}/demo_user_token/?email=${email}`)
}

export function getLoginPageNotifications() {
  return mainAxios.get(`${API_URL}/get_login_page_notifications`)
}

export function emailIsAvailable (email) {
  return mainAxios.post(`${API_URL}/email-is-available/`, { email: email })
}
export function register (userData) {
  return mainAxios.post(`${API_URL}/register/`, userData)
}

export function registerNoCard (userData) {
  return mainAxios.post(`${API_URL}/register-no-card/`, userData)
}

export function saveCompanyDetails (token, companyData) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/saveCompanyDetails`, companyData, { headers })
}
export function setNotificationsAsRead (token, ids) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/setNotificationsAsRead`, { ids }, { headers })
}
export function saveAccountName (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/saveAccountName/`, data, { headers })
}
export function savePaypalEmailForAffiliateCommission (token, paypalEmail) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/save-paypal-for-affiliate-commission/`, { paypalEmail }, { headers })
}
export function deleteCustomLogo (token) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/user/delete-logo/`, { headers })
}
export function sendApiPartnershipContactEmail (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/send-api-partnership-contact-email/`, data, { headers })
}
export function getPayAmountForPartnerApiKeyUsage (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/user/get-pay-amount-for-partner-api-key-usage/`, { headers })
}

export function addSubAccount(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/addSubAccount/`, data, { headers })
}
export function updateSubAccount(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/updateSubAccount/`, data, { headers })
}
export function deleteSubAccount(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/deleteSubAccount/`, data, { headers })
}
export function deleteSubAccounts(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/deleteSubAccounts/`, data, { headers })
}
export function getSubAccountActivityLogs (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/user/sub-account-activity-log/?${ params }`, { headers })
}
export function addDevAccount(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/add-dev-account/`, data, { headers })
}
export function deleteDevAccount(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/delete-dev-account/`, data, { headers })
}
export function updateDevCallsTopupSetting(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/update-dev-calls-auto-top-up/`, data, { headers })
}

export function createAPIAccessKey(token, data, devAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/create-api-key/`, data, { headers, params: { devAccountRelationId } })
}

export function leaveMainAccount (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/leaveMainAccount/`, data, { headers })
}

export function saveInvoicingEmail (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/user/save-invoicing-email/`, data, { headers })
}

export function changeSiteName(token, id, siteName) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/change-site-name/`, { id, siteName }, { headers })
}

export function changePageName(token, id, pageName) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/change-page-name/`, { id, pageName }, { headers })
}

export function fetchUser (token, isForIndependentRoutes) {
  const headers = authHeader(token)
  if (isForIndependentRoutes) {
    return defaultAxios.get(`${API_URL}/users/`, { headers })
  } else {
    return mainAxios.get(`${API_URL}/users/`, { headers })
  }
}

export function fetchSites (token, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/sites/`, { headers, params: { subAccountRelationId } })
}
export function fetchSite (token, siteId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/sites/${siteId}/`, { headers, params: { subAccountRelationId } })
}

export function getActiveSiteContentAudit(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/active-site-content-audit/${data ? ('?' + (new URLSearchParams(data)).toString()) : ''}`,
    { headers, params: { subAccountRelationId } }
  );
}

export function updateSiteContentAudit(token, auditId, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.patch(`${API_URL}/site-content-audit/${auditId}/`, data, { headers, params: { subAccountRelationId } })
}

export function fetchBulkRuns (token, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/bulk-runs/`, { headers, params: { subAccountRelationId } })
}
export function fetchBulkRun (token, runId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/bulk-runs/${runId}/`, { headers, params: { subAccountRelationId } })
}

export function deleteBulkRun (token, runId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/bulk-runs/${runId}/`, { headers, params: { subAccountRelationId } })
}

export function downloadBulkRunZip (token, data, subAccountRelationId) {
  const { bulkRunId } = data
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/bulk-runs/${bulkRunId}/download-all-zip/`, data, { headers, params: { subAccountRelationId } })
}

export function fetchLsaPhrases (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/lsa-phrases/`, data, { headers })
}

// export function getGuardReportDiffData (token, data) {
//   const headers = authHeader(token)
//   return mainAxios.post(`${API_URL}/get-guard-report-diff-data/`, data, { headers })
// }

export function fetchPageRuns (token, siteId, pageId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/`, { headers, params: { subAccountRelationId } })
}
export function fetchPageRunsBySearchReportId (token, searchReportId, subAccountRelationId) {
  const headers = authHeader(token)
  let data = { searchReportId }
  return mainAxios.post(`${API_URL}/fetch-page-runs-by-search-report-id/`, data, { headers, params: { subAccountRelationId } })
}
export function fetchPageRunsForExtension (token, subAccountRelationId) {
  const headers = authHeader(token)
  return defaultAxios.get(`${API_URL}/pageruns-for-extension/`, { headers, params: { subAccountRelationId } })
}
export function fetchPageRunForExtension (token, pageRunId, subAccountRelationId) {
  const headers = authHeader(token)
  return defaultAxios.get(`${API_URL}/pagerun-for-extension/`, { headers, params: { pageRunId, subAccountRelationId } })
}

export function fetchPageRun (token, siteId, pageId, runId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/${runId}/`, { headers, params: { subAccountRelationId } })
}

export function fetchPageRunBySecretKeyOrPageRunId (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/fetch-page-run-by-secret-key-or-id/`, data, { headers, params: { subAccountRelationId } })
}

// new endpoint
export function fetchPageRunNotes (token, siteId, pageId, runId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/${runId}/pagenotes/`, { headers })
}

export function createSecretKey (token, siteId, pageId, runId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/${runId}/secret-key/`, {}, { headers, params: { subAccountRelationId } })
}

export function validateSiteDomain(token, url) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/validate-site-domain/`, { url }, { headers })
}

export function createSite (token, site, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/`, site, { headers, params: { subAccountRelationId } })
}

export function fetchVariations (token, keyword, proxyLocation) {
  const headers = authHeader(token)
  let data = {
    keyword: keyword,
    proxy_location: proxyLocation
  }
  return mainAxios.post(`${API_URL}/variations`, data, { headers })
}

export function fetchPages (token, siteId, pageId, runId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/sites/${siteId}/pages/`, { headers, params: { subAccountRelationId } })
}

export function createPage (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/${data.siteId}/pages/`, data, { headers, params: { subAccountRelationId } })
}

export function createPageRunInBackend (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/${data.siteId}/pages/create-pro-run-in-backend/`, data, { headers, params: { subAccountRelationId } })
}

export function updatePageSettings (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/pages/save-settings/`, data, { headers, params: { subAccountRelationId } })
}

export function updatePagePublishStatus (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/pages/update-page-published-status/`,
    data, { headers, params: { subAccountRelationId } })
}

export function createGuardRunFromSilo (token, siloKeywordId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/silo_keywords/${siloKeywordId}/guard_run`, {}, { headers, params: { subAccountRelationId } })
}

export function pauseWatchdogSetupForPages (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/pause-watchdog-pages/`, data, { headers, params: { subAccountRelationId } })
}

export function cancelWatchdogSetupForPages (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/cancel-watchdog-pages/`, data, { headers, params: { subAccountRelationId } })
}

// export function updatePagePinStatus (token, data, subAccountRelationId) {
//   const headers = authHeader(token)
//   return mainAxios.post(`${API_URL}/sites/pages/update-pinned/`, data, { headers, params: { subAccountRelationId } })
// }

export function createDemoPageRun (data) {
  return mainAxios.post(`${API_URL}/create-demo-run/`, data)
}

export function urlUsedin24Hrs (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/is-url-used-in24hrs/`, data, { headers, params: { subAccountRelationId } })
}

export function fetchData (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/userdata`, { headers })
}

export function getPageRunStatus (token, pageRunId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/pageruns/${pageRunId}/status/`, { headers })
}

export function markAsReadForRunAutoStatusUpdate (token, pageRunId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/pageruns/${pageRunId}/mark-as-read-for-task-auto-update-notification/`,
    { headers })
}

export function getHtmlCountData(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/parser/get-html-count-data`, data, { headers })
}

export function bulkExcelUploadAndValidate(token, csvfile, subAccountRelationId) {
  const headers = authHeader(token)
  headers['Content-Type'] = 'multipart/form-data'

  console.log(headers)
  let formdata = new FormData()
  formdata.append("excelFile", csvfile)
  return mainAxios.post(`${API_URL}/upload-bulk-excel-and-validate/`, formdata, { headers, params: { subAccountRelationId } })
}

export function generateNewBulkRun(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/generate-new-bulk-run/`, data, { headers })
}

export function createNewBulkRun(token, subAccountRelationId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/new-bulk-run/`, data, { headers, params: { subAccountRelationId } })
}


export function cancelBulkRunTask(token, taskId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/cancel-bulk-run/${taskId}/`, { headers, params: { subAccountRelationId } })
}

export function downloadExcelReport(token, filePath) {
  const headers = authHeader(token)
  // headers.Accept = 'application/vnd.ms-excel'
  headers.Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return mainAxios.get(`${API_URL}/download/?file=${filePath}`, { responseType: 'blob', headers })
}

export function downloadExcelReportFromUrl(token, url) {
  let headers = {}
  headers.Accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  return mainAxios.get(url, { responseType: 'blob', headers })
}

export function fetchKeywordWizardReports(token, campaignId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/top-level-support-keyword-reports/${campaignId}/`, { headers, params: { subAccountRelationId } })
}

export function updateKeywordWizardReport(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/top-level-support-keyword-report/update/`, data, { headers, params: { subAccountRelationId } })
}

export function deleteKeywordWizardReport(token, reportId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/top-level-support-keyword-report/${reportId}/delete/`, { headers, params: { subAccountRelationId } })
}

export function downloadKeywordResearchReport(token, reportId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/keyword-research-csv-export/${reportId}/`, { headers })
}

export function resetPassword (data) {
  return mainAxios.post(`${API_URL}/resetpassword`, data)
}

export function updatePassword (data) {
  return mainAxios.post(`${API_URL}/updatepassword`, data)
}

export function changeEmailRequest (data) {
  return mainAxios.post(`${API_URL}/change-email/`, data)
}

export function getAllTasksForPageRun (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/get_all_tasks/`, data, { headers, params: { subAccountRelationId } })
}

export function addOneToCheckList (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/add_one/`, data, { headers })
}

export function archiveOneFromCheckList (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/archive_one/`, data, { headers })
}

export function restoreOneFromChecklist (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/restore_one/`, data, { headers })
}

export function archiveAllChecklistWithType(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/archive_all/`, data, { headers })
}

export function addAllToCheckList (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/add_all/`, data, { headers })
}

export function updateChecklistState (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/update_complete_state/`, data, { headers })
}

export function updateChecklistDueDate (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/add_due_date/`, data, { headers })
}
export function updateChecklistAssignee (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/assign_to/`, data, { headers })
}

export function updateChecklistStateFromExtension (token, data) { // this only makes it as completed for now
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/checklist/update_checklist_from_extension/`, data, { headers })
}

export function createChecklistNote (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/note`, data, { headers })
}

export function deleteChecklistNote (token, noteId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/checklist/note/${noteId}`, { headers })
}

export function updateChecklistNote (token, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/checklist/note`, data, { headers })
}

export function updateChecklistNoteFromExtension (token, data) {
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/checklist/note-from-extension/`, data, { headers })
}

export function downloadExcelForChecklist(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/download/`, data, {headers})
}

export function downloadTasksForProgressNote(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/checklist/download_all_tasks_excel/`, data, {headers})
}

export function downloadDocxForAiWriter(token, data) {
  const headers = authHeader(token)
  headers.Accept = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  return mainAxios.post(`${API_URL}/download/ai_writer/docx`, data, { responseType: 'blob', headers })
}

export function getChecklistForExtension(token, data) {
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/get-checklist-for-extension/`, data, {headers})
}

export function getContentBriefNotes(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/content-brief/note/all/`, data, { headers })
}

export function createContentBriefNote(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/content-brief/note/`, data, { headers })
}
export function updateContentBriefNote(token, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/content-brief/note/`, data, { headers })
}
export function deleteContentBriefNote(token, noteId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/content-brief/note/${noteId}/`, { headers })
}

export function updateContentBriefStatus(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/content-brief/update-status/ `, data, { headers })
}

export function downloadContentBrief(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/download/content-brief/ `, data, { headers })
}

export function getProratedAmountForPurchase (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/get-prorated-amount/`, data, { headers })
}

export function makePurchase (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/purchase/`, data, { headers })
}

export function createPayPalPayment (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/create-paypal-payment/`, data, { headers })
}

export function createStripePayment (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/create-stripe-payment/`, data, { headers })
}

export function convertSubscription (token) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/convert-subscription/`, {}, { headers })
}

export function buyNlpEatPackage (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/nlp-eat-credits/`, data, { headers })
}

export function buyUniversalCreditsPakcage (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/universal-credits-package-purchase/`, data, { headers })
}

export function executePayPalSubscription(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/execute-subscription/`, data, { headers })
}

export function fetchYearlyUnlimitedConversionDiscount(token, userId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/users/${userId}/yearly-unlimited-conversion/`, { headers })
}

export function cancelSubscription (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/purchase/cancel/`, data, { headers })
}

export function reactivateSubscription (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/users/${userId}/purchase/`, data, { headers })
}

export function updateUserSettings (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/users/${userId}/settings/`, data, { headers })
}

export function updateCouponCode (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/coupon/`, data, { headers })
}

export function updatePaypalEmail (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/paypal-email/`, data, { headers })
}

export function generateCancellationCode(token) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/generate-cancellation-code/`, null, { headers })
}

export function addOpenAiKey (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/add-openai-key/`, data, { headers })
  
}

export function getAvailableNlpEatPackages(token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-available-nlp-eat-packages/`, { headers })
}

export function getAvailableUniversalPackages(token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-available-universal-credit-packages/`, { headers })
}

export function getAvailableAPICreditPackages(token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-available-api-credit-packages/`, { headers })
}

export function getAvailableWhiteGloveAddonPackages(token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-available-white-glove-addon-packages/`, { headers })
}

export function deleteAccount (token, userId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/users/${userId}/delete/`, { headers })
}

export function createStripeCardAdditionLink (token, userId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/users/${userId}/payment-sources/create-add-card-link/`, { headers })
}


export function fetchPaymentSources (token, userId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/users/${userId}/payment-sources/`, { headers })
}

export function updatePaymentSource (token, userId, cardId, stripeData) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/users/${userId}/payment-sources/${cardId}/`, stripeData, { headers })
}

export function setDefaultPaymentSource (token, userId, cardOrPaymentId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/payment-sources/make-default/`, {
    id: cardOrPaymentId,
  }, { headers })
}

export function deletePaymentSource (token, userId, cardId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/users/${userId}/payment-sources/${cardId}/`, { headers })
}

export function makeNewPageRun (token, data, subAccountRelationId) {
  const { siteId, pageId } = data
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/rerun/`, data, { headers, params: { subAccountRelationId } })
}

export function loadApiUsageForUser(token, data, devAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/load-api-usage-data/`, data, { headers, params: { devAccountRelationId } })
}

export function getUsersForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/useraccounts/?${params}`, { headers })
}

export function loadAdminComparisonData (token, primaryStartDate, primaryEndDate, secondaryStartDate, secondaryEndDate, source, aggregator) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/comparison-data/?primaryStartDate=${primaryStartDate}&primaryEndDate=${primaryEndDate}&secondaryStartDate=${secondaryStartDate}&secondaryEndDate=${secondaryEndDate}&source=${source}&aggregator=${aggregator}`, { headers })
}

export function downloadAllUserExcel (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/download_all_users_excel/`, data, { headers })
}

export function downloadAllSales (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/download_all_sales/`, { headers })
}

export function updateUserAccountForAdmin (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/useraccounts/`, data, { headers })
}

export function resendRegistrationEmailForAdmin(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/useraccounts/resend_registration_email/`, data, { headers })
}

export function confirmEmailAsValidated(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/useraccounts/validate-user-email-by-admin/`, data, { headers })
}

export function adminResetUsageLimitForUser(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/users/reset-user-usage-limits/`, data, { headers })
}

export function adminCancelSubscription(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/users/cancel-subscription/`, data, { headers })
}

export function getCouponsForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/coupons/?${params}`, { headers })
}

export function autoGenCouponCode (token, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/admin/coupons/`, data, { headers })
}

export function createCouponForAdmin (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/coupons/`, data, { headers })
}

export function updateCouponForAdmin (token, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/admin/coupons/update/`, data, { headers })
}

export function deleteCouponForAdmin (token, id) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/admin/coupons/${id}/`, { headers })
}

export function addAliasToCouponForAdmin (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/coupons/add_alias/`, data, { headers })
}

export function createAdminUserComment(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/admin-user-comment/`, data, { headers })
}

export function getHistoryForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/history/?${params}`, { headers })
}

export function getKeywordResearchForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/keyword_research/?${params}`, { headers })
}

export function getWatchDogTrackHistoryForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/watchdog-track-history/?${params}`, { headers })
}

export function getGuardRunsForUser (token, params, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-guard-runs/?${params}`,
    { headers, params: { subAccountRelationId } })
}

export function getAIRunsForUser (token, params, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-ai-runs/?${params}`, { headers, params: { subAccountRelationId } })
}

export function getAIRunsForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/get-ai-runs/?${params}`, { headers })
}

export function getCreditUsageForUser (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/credit-logs/?${ params }`, { headers })
}

export function getKeywordResearchCreditUsageForUser (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/keyword-research-credit-logs/?${ params }`, { headers })
}

export function getBulkCreditLog (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/bulk-credit-logs/?${ params }`, { headers })
}

export function getAllUsersForAdmin (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/allusers/`, { headers })
}

export function getAllNotificationsForAdmin (token, params) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/notifications/?${params}`, { headers })
}

export function createAdminNotification (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/notifications/`, data, { headers })
}

export function updateNotificationForAdmin (token, notification) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/admin/notifications/`, notification, { headers })
}

export function deleteNotificationForAdmin (token, id) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/admin/notifications/${id}/`, { headers })
}

export function getSales (token, startDate, endDate) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/sales/?startDate=${startDate}&endDate=${endDate}`, { headers })
}

export function getNewUserStats (token, startDate, endDate) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/new-users/?startDate=${startDate}&endDate=${endDate}`, { headers })
}

export function getRunsPerDay (token, startDate, endDate) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/runs/?startDate=${startDate}&endDate=${endDate}`, { headers })
}

export function getTotalUsagePerDay (token, days) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/user-usage/?days=${days}`, { headers })
}

export function getTotalUsers (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/users/`, { headers })
}

export function getAffiliateSales (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/affiliate-sales/`, { headers })
}

export function getAffiliatePayouts (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/affiliate-payouts/`, { headers })
}

export function removeFromPayout (token, data) {
  const headers = authHeader(token)
  return mainAxios.put(`${API_URL}/admin/affiliate-payouts/`, data, { headers })
}

export function executePayout (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/affiliate-payouts/`, data, { headers, timeout: 12000 }) // set timeout to 10 seconds
}

export function getPaypalRefunds (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/admin/paypal-refunds/`, { headers })
}

export function executePaypalRefund (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/paypal-refunds/`, data, { headers })
}

export function testCancelledWatchdogSetup (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/admin/test-cancelled-watchdog-campaign/`, data, { headers })
}


export function deleteSite (token, siteId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/sites/${siteId}/`, { headers })
}

export function deleteSites (token, siteIds) {
  const headers = authHeader(token)
  let data = { siteIds }
  return mainAxios.post(`${API_URL}/sites/delete-sites/`, data, { headers })
}

export function deletePage (token, siteId, pageId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/sites/${siteId}/pages/${pageId}/`, { headers })
}

export function deletePages (token, siteId, pageIds) {
  const headers = authHeader(token)
  let data = { pageIds }
  return mainAxios.post(`${API_URL}/sites/${siteId}/pages/delete-pages/`, data, { headers })
}

export function updatePinPageRun (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/update-pin-pagerun/`, data, { headers })
}

export function deletePageRun (token, siteId, pageId, pageRunId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/${pageRunId}/`, { headers })
}

export function deletePageRuns (token, siteId, pageId, pageRunIds) {
  const headers = authHeader(token)
  let data = { pageRunIds }
  return mainAxios.post(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/delete-pageruns/`, data, { headers })
}

export function removeTermFromCB (token, data) {
  const headers = authHeader(token)
  return axios.post(`${API_URL}/sites/${data.siteId}/pages/${data.pageId}/pageruns/${data.pageRunId}/remove-term/`, data, { headers })
}

export function recoverTermForCB(token, data) {
  const headers = authHeader(token)
  return axios.post(`${API_URL}/sites/${data.siteId}/pages/${data.pageId}/pageruns/${data.pageRunId}/recover-term/`, data, { headers })
}

export function removeTermsFromCB (token, data) {
  const headers = authHeader(token)
  return axios.post(`${API_URL}/sites/${data.siteId}/pages/${data.pageId}/pageruns/${data.pageRunId}/remove-terms/`, data, { headers })
}

export function toggleScoringMethodOfCB(token, data) {
  const headers = authHeader(token)
  return axios.post(`${API_URL}/pageruns/${data.pageRunId}/toggle-scoring-method-of-cb/`, data, { headers })
}

export function setContentBriefCustomWordCount(token, data) {
  const headers = authHeader(token)
  return axios.post(`${API_URL}/pageruns/${data.pageRunId}/set-content-brief-custom-word-count/`, data, { headers })
}

export function acknowledgeForContentBriefScoreNotChanged (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/acknowledged_for_same_content_brief_score_modal/`, data, { headers, params: { subAccountRelationId } })
}

export function sendContactUs (email, name, message) {
  return mainAxios.post(`${API_URL}/contact_us/`,{ email, name, message })
}

export function updatePageRunNote (token, data) {
  const headers = authHeader(token)
  const { siteId, pageId, pageRunId } = data
  return mainAxios.post(`${API_URL}/sites/${siteId}/pages/${pageId}/pageruns/${pageRunId}/notes/`, data, { headers })
}

export function fetchValidatedCoupons (token, promoCode, isRegisterPage) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/validate-coupons/?promoCode=${promoCode}&isRegisterPage=${isRegisterPage}`, { headers })
}

export function fetchProxies (token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/proxies/`, { headers })
}

export function getUserNotifications (token, userId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/users/${userId}/notifications/`, { headers })
}

export function deleteUserNotification (token, userId, notificationId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/users/${userId}/notifications/${notificationId}/`, { headers })
}

export function fetchInvoiceData (token, userId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/users/${userId}/invoices/`, { headers })
}

export function exportInvoices (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/invoices/`, data, { headers })
}

export function exportRefundInvoices (token, userId, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/invoices/refund/`, data, { headers })
}

export function updateScoringStrategy (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/pageruns/${data.pageRunId}/strategy-score/`, data, { headers, params: { subAccountRelationId } })
}

export function updateSummaryReportDay(token, userId, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/update-summary-day/`, data, { headers, params: { subAccountRelationId } })
}

export function neverMindClicked(token, userId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/users/${userId}/never-mind-clicked/`, {}, { headers })
}

export function testRunForSummaryReport(token, userId, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(`${API_URL}/users/${userId}/test-summary-email/`, { headers, params: { subAccountRelationId } })
}

export function updateRecommendationsForPage (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/${data.siteId}/pages/${data.pageId}/update-recommendations/`, data, { headers })
}

export function validatePageAnalysisUrls(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/validateurls/`, data, { headers })
}

export function validateUrlForEditor(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/validateurl-for-editor/`, data, { headers })
}

export function fetchTaskResult(taskId) {
  return mainAxios.get(`${API_URL}/task/${taskId}/results/`)
}

export function submitGoogleSearchTask(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/parse-google-search/`, data, { headers })
}

export function filterGoogleVariations(token, keyword, secondaryKeywords, variations) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/filter-google-variations/`, { keyword, secondaryKeywords, variations }, { headers })
}

export function fetchGoogleSearchLocations(token) {
  const headers = authHeader(token)
  return mainAxios(`${API_URL}/google-search-locations/`, { headers })
}

export function saveProjectSettings(token, data) { // data { siteId, siteName, targetLang, proxyLocation, ignoreSpaces }
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/sites/save-settings/`, data, { headers })
}

// export function updateProjectPinStatus(token, data) { // data { siteId, isPinned }
//   const headers = authHeader(token)
//   return mainAxios.post(`${API_URL}/sites/update-pinned/`, data, { headers })
// }

export function saveRevisionHistoryForContentEditor(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/save-revision-history-for-content-editor/`, data, { headers })
}

export function saveEditedSourceCode(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/save-edited-source-code/`, data, { headers, params: { subAccountRelationId } })
}

export function loadSavedSourceCode(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/load-saved-source-code/`, data, { headers, params: { subAccountRelationId } })
}
export function loadForSpecificRevisionData(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/load-for-specific-revision-data/`, data, { headers })
}

export function getWeeklyPageRunStats () {
  return mainAxios.get(`${API_URL}/get_weekly_pagerun_stats/`)
}

export function unsubscribeWatchdogMadeByAdmin (data) {
  return defaultAxios.post(`${API_URL}/unsubscribe-watchdog-made-admin/`, data)
}


// test api endpoint
export function openApiTest ( data ) {
  return defaultAxios.post(`${API_URL}/openai-test/`, data)
}

export function getGenerateSchema (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/ai-run/page-run-schema`, data, { headers, params: { subAccountRelationId } })
}

export function getRequestSchema (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return defaultAxios.post(`${API_URL}/ai-run/request-schema`, data, { headers, params: { subAccountRelationId } })
}

export function getPromptForAI (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/get-prompt-for-ai/`, data, { headers, params: { subAccountRelationId } })
}

export function getAIAnswerForContentPrompt (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/get-ai-result-for-content-prompt-item/`, data, { headers, params: { subAccountRelationId } })
}

export function getAnswerWithAI (token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/get-answer-with-ai/`, data, { headers })
}

export function loadAiRun (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/get-ai-run/`, data, { headers, params: { subAccountRelationId } })
}

export function aiRunChangeCurrentSelection(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/ai-run/change-current-selection/`, data, { headers })
}

export function aiRunSaveEditedContent(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/ai-run/save-edited-content/`, data, { headers })
}

export function aiRunChangeCurrentSelectionForRichItem (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/ai-run/change-current-selection-for-rich-item/`, data, { headers, params: { subAccountRelationId } })
}

export function aiRunUpdateEditedContentForRichItem (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/ai-run/save-edited-content-for-rich-item/`, data, { headers, params: { subAccountRelationId } })
}

export function deleteAIRun (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/ai-run/delete-ai-run/`, data, { headers, params: { subAccountRelationId } })
}

export function aiRunGetFullHtml (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/ai-run/get-full-html/`, data, { headers, params: { subAccountRelationId } })
}

export function createKeywordResearchCampaign (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/campaign/create/`, data, { headers, params: { subAccountRelationId } })
}

export function getCampaignById (token, campaignId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/campaign/${ campaignId }/`, { headers, params: { subAccountRelationId } })
}

export function getCampaignLatestGeneratedReports (token, campaignId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/campaign/${ campaignId }/latest_generated_reports/`, { headers, params: { subAccountRelationId } })
}

export function getCampaignsForSite (token, siteId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/site/${ siteId }/get-campaigns/`, { headers, params: { subAccountRelationId } })
}

export function createKeywordInsightResearch (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-insight-research/`, data, { headers, params: { subAccountRelationId } })
}

export function getKeywordResearchHistoryForCampaign (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/get-keyword-research-history-for-compaign/`, data, { headers, params: { subAccountRelationId } })
}

export function loadKeywordResearchData (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/load-one-keyword-research/`, data, { headers, params: { subAccountRelationId } })
}

export function updateKeywordsVisibility (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-research/update-keywords-visibility/`, data, { headers, params: { subAccountRelationId } })
}

export function keywordResearchAnalyzeWithAI(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-research/analyze-with-ai/`, data, { headers, params: { subAccountRelationId } })
}

export function updateKeywordLabel(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-research/update-keyword-label/`, data, { headers, params: { subAccountRelationId } })
}

export function getKeywordsFromTopic (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/support-keyword/get-keywords-from-topic/`, data, { headers, params: { subAccountRelationId } })
}

export function clusteringUserKeywordsExcel (token, excelFile, subAccountRelationId) {
  const headers = authHeader(token)
  headers['Content-Type'] = 'multipart/form-data'

  let formdata = new FormData()
  formdata.append("keywordsFile", excelFile)
  return mainAxios.post(`${API_URL}/support-keyword/clustering-for-user-keywords/`, formdata, { headers, params: { subAccountRelationId } })
}

export function createSupportKeywordResearch (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/support-keyword/keyword-research-support/`, data, { headers, params: { subAccountRelationId } })
}

export function getSupportKeywordResearchHistoryForCampaign (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/support-keyword/get-keyword-research-history-for-compaign/`, data, { headers, params: { subAccountRelationId } })
}

export function loadSupportKeywordResearchData (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/support-keyword/load-one-keyword-research/`, data, { headers, params: { subAccountRelationId } })
}

export function saveKeywordWizardResult (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-wizard/save-result/`, data, { headers, params: { subAccountRelationId } })
}

export function loadRecentKeywordWizardData (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-wizard/load-wizard-history/`, data, { headers, params: { subAccountRelationId } })
}

export function loadKeywordWizardMonthlySearchAndExtras(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-wizard/load-monthly-search-and-extras/`, data, { headers, params: { subAccountRelationId } })
}

export function loadKeywordWizardLabels(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-wizard/load-keyword-labels/`, data, { headers, params: { subAccountRelationId } })
}

export function wizardUpdateKeywordLabel(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-wizard/update-keyword-label/`, data, { headers, params: { subAccountRelationId } })
}

export function topLevel_getKeywordsFromTopic (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/top-level-support-keyword/get-keywords-from-topic/`, data, { headers, params: { subAccountRelationId } })
}

export function topLevel_createSupportKeywordResearch (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/top-level-support-keyword/keyword-research-support/`, data, { headers, params: { subAccountRelationId } })
}

export function topLevel_getSupportKeywordResearchHistoryForCampaign (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/top-level-support-keyword/get-keyword-research-history-for-compaign/`, data, { headers, params: { subAccountRelationId } })
}

export function topLevel_loadSupportKeywordResearchData (token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/top-level-support-keyword/load-one-keyword-research/`, data, { headers, params: { subAccountRelationId } })
}

export function addKeywordToTrack(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-track/add-track-keyword/`, data, { headers, params: { subAccountRelationId } })
}

export function bulkTrackKeywordToTrack(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-track/bulk-track-keyword-update/`, data, { headers, params: { subAccountRelationId } })
}

export function removeKeywordFromTrack(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-track/remove-track-keyword/`, data, { headers, params: { subAccountRelationId } })
}

export function updateTrackEmailNotificationSetting(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-track/update-email-notification-setting/`, data, { headers, params: { subAccountRelationId } })
}

export function getKeywordTrackForCampaign(token, campaignId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/keyword-track/${ campaignId }/`, { headers, params: { subAccountRelationId } })
}

export function startKeywordTrack(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-track/start-tracking-keywords/`, data, { headers, params: { subAccountRelationId } })
}

export function updateKeywordResearchReport(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-research/update/`, data, { headers, params: { subAccountRelationId } })
}

export function deleteKeywordResearchReport(token, reportId, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.delete(`${API_URL}/keyword-research/${reportId}/delete/`, { headers, params: { subAccountRelationId } })
}

export function getMonthlySearches(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/data-for-seo/keyword-data/get-monthly-search/`, data, { headers, params: { subAccountRelationId } })
}

export function createKeywordGapAnalysis(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-gap-analysis/`, data, { headers, params: { subAccountRelationId } })
}
export function loadKeywordGapHistory(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-gap/history/`, data, { headers, params: { subAccountRelationId } })
}

export function loadOneKeywordGapData(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/keyword-gap/load-one-keyword-gap-data/`, data, { headers, params: { subAccountRelationId } })
}

export function playWithOpenAI(token, data) {
  const headers = authHeader(token)
  return mainAxios.post(`${API_URL}/play-with-ai/`, data, { headers })
}

export function getProjectsForWorkstation(token, params, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/workstation/projects/?${params}`, { headers, params: { subAccountRelationId } })  
}

export function getPagesForWorkstation(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/workstation/${data.siteId}/pages/?${data.params}`, { headers, params: { subAccountRelationId } })  
}

export function getPageRunForWorkStation(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(
    `${API_URL}/workstation/${data.siteId}/pages/${data.pageId}/`,
    { headers, params: { subAccountRelationId } }
  )
}

export function getSilos(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/silos/${data ? ('?' + (new URLSearchParams(data)).toString()) : ''}`,
    { headers, params: { subAccountRelationId } }
  );
}

export function getSilo(token, siloId, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/silos/${siloId}`,
    { headers, params: { subAccountRelationId } }
  );
}

export function createSilo(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.post(`${API_URL}/silo/`, data, { headers, params: { subAccountRelationId } });
}

export function updateSilo(token, siloId, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.put(`${API_URL}/silo/${siloId}`, data, { headers, params: { subAccountRelationId } });
}

export function deleteSilo(token, siloId, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.delete(`${API_URL}/silo/${siloId}`, { headers, params: { subAccountRelationId } });
}

export function addKeywordToSilo(token, siloId, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.post(`${API_URL}/silo_keyword/${siloId}`, data, { headers, params: { subAccountRelationId } });
}

export function addKeywordsToSilo(token, siloId, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.post(`${API_URL}/silo_keywords/${siloId}`, data, { headers, params: { subAccountRelationId } });
}

export function removeKeywordFromSilo(token, siloKeywordId, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.delete(`${API_URL}/silo_keyword/${siloKeywordId}`, {
    headers, params: { subAccountRelationId }
  });
}

export function getSiloKeywords(token, params, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/silo_keywords/`,
    { headers, params: {...params, subAccountRelationId } },
  );
}

export function getSiloKeyword(token, siloKeywordId, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/silo_keywords/${siloKeywordId}`,
    { headers, params: { subAccountRelationId } },
  );
}

export function getSiloPDFReport(token, data, subAccountRelationId) {
  const baseUrl = `${API_URL}/silo_keyword/pdf_report/${data.siloKeywordId}`;
  const config = {
    headers: authHeader(token),
    responseType: "blob",
    params: {
      reportType: data.reportType,
      secretKey: data.secretKey,
      sourceType: data.sourceType,
      subAccountRelationId
    }
  };
  
  return mainAxios.get(baseUrl, config);
}

export function getSiloWordReport(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  const config = { 
    headers,
    params: {
      reportType: data.reportType,
      secretKey: data.secretKey,
      sourceType: data.sourceType,
      subAccountRelationId
    }
  }
  return mainAxios.get(
    `${API_URL}/silo_keyword/word_report/${data.siloKeywordId}?reportType=${data.reportType}`,
    config
  );
}

export function getReportHeader(token, data) {
  const baseUrl = `${API_URL}/report_header_image/`;
  const config = {
    headers: authHeader(token),
    params: {
      reportType: data.reportType,
    }
  };

  return mainAxios.get(baseUrl, config);
}

export function getAvailableSiloContentReportPackages(token) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-available-silo-content-report-packages/`, { headers })
}

export function getAvailableSiloContentReportDeliveryOptions(token, data, subAccountRelationId) {
  const headers = authHeader(token)
  return mainAxios.get(`${API_URL}/get-available-silo-content-report-delivery-options/${data ? ('?' + (new URLSearchParams(data)).toString()) : ''}`, { headers, params: { subAccountRelationId } })
}

export function getSiloContentReportOrders(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/silo-content-report-orders/${data ? ('?' + (new URLSearchParams(data)).toString()) : ''}`,
    { headers, params: { subAccountRelationId } }
  );
}

export function updateSiloContentReportOrder(token, siloId, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.patch(`${API_URL}/silo-content-report-orders/${siloId}`, data, { headers, params: { subAccountRelationId } });
}


export function getTaskStatus(taskId) {
  return defaultAxios.get(`${API_URL}/task/${taskId}/results/`)
}

export function getActiveTasks(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.get(
    `${API_URL}/tasks/${data ? ('?' + (new URLSearchParams(data)).toString()) : ''}`,
    { headers, params: { subAccountRelationId } }
  );
}

export function acknowledgeTask(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.post(`${API_URL}/tasks/acknowledge/`, data, { headers, params: { subAccountRelationId } });
}

export function updateSiloKeywordFlag(token, data, subAccountRelationId) {
  const headers = authHeader(token);
  return mainAxios.post(`${API_URL}/silo_keyword_flags/${data.siloKeywordId}`, data, { headers, params: { subAccountRelationId } });
}

export function fetchUserHasPayments(token) {
  const headers = authHeader(token);
  return mainAxios.get(`${API_URL}/users/hasPaymentSource/`, { headers });
}
