<template>
  <div class="container-launch px-4 py-8 mt-10" style="flex: 1; max-width: 1150px;">
    <div class="flex flex-column justify-space-between width704">
      <div
        v-if="!loading"
        class="flex"
        style="gap: 20px;"
      >
        <div class="step-control">
          <v-btn
            :style="currentStep < 2 || currentStep > STEPS.length ? 'visibility: hidden;':''"
            icon
            outlined
            @click="currentStep -= 1"
          >
            <img src="/static/orange_caret_left_icon.svg" width="12" /> 
          </v-btn>
        </div>

        <div
          v-if="activeAudit && currentStep === 5"
          class="onboarding-completed"
        >
          <img src="/static/whote_glove_site_content_audit_onboarding_completed.png" />
          <div class="mainColor--text" style="font-weight: 800; font-size: 1.4rem; line-height: 1.5;">
            White Glove services
            <br/>
            onboarding completed <i class="material-icons font-weight-bold" style="color: #0CB370; font-size: 1.5rem; vertical-align: sub;">check</i>
            <br/>
          </div>
        </div>
        
        <div v-else class="flex flex-column justify-space-between" style="flex: 1;">

        
          <div class="flex" style="align-items: center;">
            <img src="/static/thunder_icon.svg" width="12" />
            <div class="ml-2 mainColor--text font-weight-bold" style="flex: 1;">Complete your White Glove services onboarding</div>
          </div>
    
          <div class="steps-status mt-4 mb-8">
            <div
              v-for="(step, idx) in STEPS"
              :key="idx"
              :class="{
                'step-item': true,
                'active': currentStep === (idx + 1),
                'completed': isStepValid(idx + 1)
              }"
            >
              <div class="step-dot">
                <i class="material-icons">check</i>
              </div>
              <div class="step-label">
                {{ step.label }}
              </div>
              <div class="step-seperator" v-if="idx < (STEPS.length - 1)"></div>
            </div>
          </div>
    
          <div
            v-if="currentStep === 1"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Buy content audit for this site</div>
            <div class="step-text step-text-description mb-4">
              Buy a full website content audit and EEAT analysis with recommendations and a project plan.
            </div>
            <v-btn
              v-if="isStepValid(currentStep)"
              rounded
              color="anchor1Color"
              class="success-disabled-btn button-with-icon text-none padding8 height38"
              tag="a"
              :disabled="true"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
              Audit purchased
            </v-btn>
            <v-btn
              v-else
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text padding8 height38"
              tag="a"
              @click="buyNow();"
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">shopping_basket</i>
              Buy now for ${{ contentAuditPackage.price }}
            </v-btn>
          </div>
    
          <div
            v-if="activeAudit && currentStep === 2"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Tell us more about your site</div>
            <div class="step-text step-text-description mb-4">
              Fill out the form so that we have all details about your site. Once you complete the form, you will receive the copy of your replies to your POP email address.
            </div>
            <div
              v-if="isStepValid(currentStep)"
              style="display: flex; flex-direction: row; gap:12px; align-items: center;"
            >
              <v-btn
                rounded
                color="anchor1Color"
                class="success-disabled-btn button-with-icon text-none padding8 height38"
                tag="a"
                :disabled="true"
                @click=""
              >
                <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
                Form filled
              </v-btn>
              <a href="#"
                class="mainColor--text font-weight-bold"
                style="text-decoration: underline;"
                @click.prevent.stop="() => {
                  showFormModal = true
                }">
                {{ isMissionControlSession ? 'Edit/view responses' : 'Edit your responses' }}
              </a>
            </div>
            <v-btn
              v-else
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text padding8 height38"
              tag="a"
              @click="showFormModal = true"
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">list</i>
              Fill the form
            </v-btn>
          </div>
    
          <div
            v-if="activeAudit && currentStep === 3"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Set up Keyword Research</div>
            <div class="step-text step-text-description mb-4">
              In this step we will ask you to confirm your domain name, as well as location and language for the website. This will give you and your POP team access to all keyword research data & metrics.
            </div>
            <v-btn
              v-if="isStepValid(currentStep)"
              rounded
              color="anchor1Color"
              class="success-disabled-btn button-with-icon text-none padding8 height38"
              tag="a"
              :disabled="true"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
              Keyword Research set
            </v-btn>
            <v-btn
              v-else
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text padding8 height38"
              tag="a"
              @click="$store.commit('triggerCreateDomainCampaignModal')"
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">vpn_key</i>
              Set up Keyword Research
            </v-btn>
          </div>
    
          <div
            v-if="activeAudit && currentStep === 4"
            :class="{
              'step-content': true,
              'completed': isStepValid(currentStep)
            }"
          >
            <div class="step-text step-text-number mb-3">Step {{currentStep}}</div>
            <div class="step-text step-text-title mb-2">Connect Google Search Console</div>
            <div class="step-text step-text-description mb-4">
              Connect your GSC account to POP: the integration with Google Search Console will let us analyze organic search metrics related to your site.
            </div>
            <v-btn
              v-if="isStepValid(currentStep)"
              rounded
              color="anchor1Color"
              class="success-disabled-btn button-with-icon text-none padding8 height38"
              tag="a"
              :disabled="true"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">task_alt</i>
              GSC Connected
            </v-btn>
            <v-btn
              v-else
              rounded
              color="anchor1Color"
              class="button-with-icon text-none whiteColor--text padding8 height38"
              tag="a"
              @click=""
            >
              <i class="material-icons-outlined" style="margin-right: 10px; font-size: 1.2rem;">build_circle</i>
              Connect GSC
            </v-btn>
          </div>

        </div>

        <div class="step-control">
          <v-btn
            :style="currentStep > (STEPS.length - 1) ? 'visibility: hidden;':''"
            icon
            outlined
            :disabled="!isStepValid(currentStep)"
            @click="currentStep += 1"
          >
            <img src="/static/orange_caret_right_icon.svg" width="12" />   
          </v-btn>
        </div>

        <form-modal
          v-if="activeAudit"
          :active="showFormModal"
          :site="site"
          :activeAudit="activeAudit"
          @close="showFormModal = false"
          @auditUpdated="$store.commit('setSiteContentAuditBySiteId', {siteId: site.id, audit: $event})"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormModal from './FormModal.vue'

const STEPS = [
  {
    label: 'Buy audit'
  },
  {
    label: 'Fill the form'
  },
  {
    label: 'Keyword data'
  },
  {
    label: 'Connect GSC'
  }
]

export default {
  components: { FormModal },
  props: ['site', 'keywordResearchCardRef'],
  data () {
    return {
      loading: true,
      contentAuditPackage: null,
      currentStep: 1,
      stepsCount: 4,
      showFormModal: false,
    }
  },
  computed: {
    STEPS: () => STEPS,
    user () {
      if (this.$store.state.user) {
        return this.$store.state.user
      }
      return null
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
    activeAudit () {
      return this.$store.state?.siteContentAuditsBySiteId?.[this.site.id]
    },
  },
  watch: {
    'site.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.loadData()
        }
      },
      immediate: true,
    }
  },
  methods: {
    isStepValid(step) {
      if (step === 1) {
        return Boolean(this.activeAudit)
      }
      if (step === 2) {
        return this.isStepValid(step - 1) && this?.activeAudit?.status === 'form_submitted'
      }
      if (step === 3) {
        return this.isStepValid(step - 1) && Boolean(this?.activeAudit?.hasAssociatedDomainLevelCampaign)
      }
      if (step === 4) {
        return this.isStepValid(step - 1) && Boolean(this?.activeAudit?.hasAssociatedScenario1Data) && Boolean(this?.activeAudit?.hasAssociatedScenario2Data)
      }
      return false;
    },
    loadData () {
      return this.$store.dispatch('getActiveSiteContentAudit', {
        siteId: this.site.id,
      })
        .then(response => {
          if (response.data && response.data.status == 'SUCCESS') {
            this.$store.commit('setSiteContentAuditBySiteId', {siteId: this.site.id, audit: response.data.data})
            return Promise.resolve()
          }
        })
        .then(() => {
          return this.$store.dispatch('getAvailableWhiteGloveAddonPackages')
            .then(response => {
              if (response.data && response.data.status == 'SUCCESS') {
                this.contentAuditPackage = response.data.packages.filter(p => p.key === "WHITE_GLOVE_ADDON_CONTENT_AUDIT")[0];
                return Promise.resolve()
              }
            })
        })
        .then(() => {
          // go to the last valid step or default to first step
          let currentStep = 1
          for (let index = 1; index <= this.stepsCount; index++) {
            if (this.isStepValid(index)) {
              currentStep = index + 1;
            } else {
              break;
            }
          }
          this.currentStep = currentStep;
          return Promise.resolve()
        })
        .finally(() => {
          this.loading = false;
        })
    },
    buyNow() {
      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'WHITE_GLOVE_ADDON_PURCHASE',
        selectedPackage: 'WHITE_GLOVE_ADDON_CONTENT_AUDIT',
        userID: this?.selectedSubAccountRelation?.ownerId || this.user.id,
        selectedSiteId: this.site.id,
        redirect_front_url: this.$router.currentRoute.fullPath,
        redirect_front_for_cancel_url: this.$router.currentRoute.fullPath,
      })
      .then(function (response) {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .finally(() => {
        this.$store.commit('showLoading');
      })
      },
  },
  mounted () {

  },
}
</script>

<style lang="scss" scoped>
.container-launch {
  display: flex;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  border-radius: 12px;

  .width704 {
    width: 44rem;
  }

  .v-btn.v-btn--disabled.success-disabled-btn {
    background-color: #0CB370 !important;
    color: #fff !important;

    .material-icons-outlined {
      font-size: 1.2rem;
    }
  }

  .onboarding-completed {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;

    img {
      height: 160px;
      margin-bottom: -50px;
    }
  }

  .step-control {
    padding-top: 110px;
    text-align: center;

    .v-btn {
      background-color: #fff;
      border-color: #CCCCCC;
      border-radius: 6px;
      box-shadow: 0px 0.32px 7.93px 0px rgba(0, 0, 0, 0.11);

      &.v-btn--disabled {
        opacity: 0.4 !important;
      }
    }
  }

  .steps-status {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .step-item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      &, * {
        cursor: default;
      }

      .step-label {
        font-weight: bold;
        color: #9D9D9D;
      }
      .step-dot {
        box-sizing: border-box;
        border-radius: 100px;
        border: 1px solid #CCCCCC;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        line-height: 25px;
        
        .material-icons {
          visibility: hidden;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      .step-seperator {
        display:inline-block;
        width: 40px;
        height: 2px;
        background: #9D9D9D;
      }

      &.active {
        .step-label {
          font-weight: bold;
          color: var(--v-mainColor-base);
        }
        .step-dot {
          border: 5px solid #FF5C00;
        }
      }

      &.completed {
        .step-label {
          color: #0CB370;
        }
        .step-dot {
          border: none;
          background: #0CB370;
          .material-icons {
            visibility: visible;
            color: #fff;
          }
        }
        .step-seperator {
          display:inline-block;
          width: 40px;
          height: 2px;
          background: #0CB370;
        }
      }
    }
  }

  .step-content {
    
    .v-btn {
      letter-spacing: initial;
    }
    
    .step-text {
      color: var(--v-primary-base);
    }

    .step-text-number {
      font-size: 0.95rem;
      font-weight: 800;
    }
  
    .step-text-title {
      font-size: 1.25rem;
      font-weight: 800;
    }
  
    .step-text-description {
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.6rem;
    }

    &.completed {
      .step-text {
        text-decoration: line-through;
      }
    }
  }

  .height38 {
    height: 2.375rem !important;
  }

  .padding8 {
    padding: 8px 12px !important;
  }

  .cross-container {
    margin-top: -10px;
  }
}
</style>

<style lang="scss">

.container-launch .step-control .v-btn.v-btn--plain:not(.v-btn--active):not(.v-btn--disabled):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: 1 !important;
}

</style>