<template>
<div>
  <div class="mt-10">
    <div class="scenarios mt-5">
      <router-link
        v-if="options.includes('PAGE_OPPORTUNITY')"
        custom
        :to="`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/page-opportunity`"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <div
          :class="{ 'is-active': isActive }"
          @click="navigate"
          style="position:relative;"
        >
          <div
            v-if="showWhiteGloveSiteContentAuditOboardingTip('scenario1')"
            class="white-glove-site-content-audit-oboarding-tip"
          >
            <v-tooltip right color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <pulsating-dot />
                </div>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                Click to open the dashboard and connect your GSC to POP.
              </div>
            </v-tooltip>  
          </div>
          <img src="/static/keyword-wizard-step-1.svg" />
          <div class="name">Step 1: Pages</div>
          <div class="explain">
            Find and select existing pages<br/>with high traffic potential
          </div>
        </div>
      </router-link>
      <router-link
        v-if="options.includes('SITE_URL_EXIST')"
        custom
        :to="`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/site-url`"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <div
          :class="{ 'is-active': isActive }"
          @click="navigate"
        >
          <img src="/static/keyword-wizard-step-2.svg" />
          <div class="name">Step 2: Top-level keywords for page</div>
          <div class="explain">
            I have both established site<br />and target page URL
          </div>
        </div>
      </router-link>
      <router-link
        v-if="options.includes('SITE_ONLY_EXIST')"
        custom
        :to="`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/site-only`"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <div
          :class="{ 'is-active': isActive }"
          @click="navigate"
          style="position:relative;"
        >
          <div
            v-if="showWhiteGloveSiteContentAuditOboardingTip('scenario2')"
            class="white-glove-site-content-audit-oboarding-tip"
          >
            <v-tooltip right color="transparent">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <pulsating-dot />
                </div>
              </template>
              <div class="white-bg-tooltip small-tooltip">
                Click to open the dashboard and connect your GSC to POP.
              </div>
            </v-tooltip>  
          </div>
          <img src="/static/keyword-wizard-step-2.svg" />
          <div class="name">Step 2: Top-level keywords</div>
          <div class="explain">
            Find easy-to-rank keywords for<br />the pages you’ve selected
          </div>
        </div>
      </router-link>
      <router-link
        v-if="options.includes('SUPPORTING_KEYWORD_RESEARCH')"
        custom
        :to="`/sites/${siteId}/campaign/${campaignId}/keyword-wizard/existing-content/supporting-keyword-research`"
        v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <div
          :class="{ 'is-active': isActive }"
          @click="navigate"
        >
          <img src="/static/keyword-wizard-step-3.svg" />
          <div class="name">Step 3: Supporting keywords</div>
          <div class="explain">
            Build out a network of supporting<br/>keywords to strengthen your top-level pages
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <div class="mt-10">
    <div class="card-small-title">
      Connect Google account
    </div>
    <div class="mt-5" style="position: relative;">
      <div
        v-if="showWhiteGloveSiteContentAuditOboardingTipOnGoogleButton()"
        class="white-glove-site-content-audit-oboarding-tip google-tip"
      >
        <v-tooltip right color="transparent">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <pulsating-dot />
            </div>
          </template>
          <div class="white-bg-tooltip small-tooltip">
            Give POP access to your GSC here
          </div>
        </v-tooltip>  
      </div>
      <google-login></google-login>      
    </div>
  </div>
</div>
</template>

<script>
import GoogleLogin from '@/components/Campaigns/GoogleLogin.vue';
import PulsatingDot from '../../../../common/PulsatingDot.vue';



export default {
  props: {
    options: {
      default: [],
    },
    curScenario: {
      default: ''
    },
    campaignId: {

    },
    siteId: {

    },
  },
  components: {
    GoogleLogin,
    PulsatingDot,
  },
  data () {
    return {}
  },
  computed: {
    googleUser () {
      return this.$store.state.googleUser
    },
  },
  methods: {
    showWhiteGloveSiteContentAuditOboardingTip (scenario) {
      const scenarioToKey = {
        'scenario1': 'hasAssociatedScenario1Data',
        'scenario2': 'hasAssociatedScenario2Data',
      }
      const scenarioToPathKey = {
        'scenario1': 'page-opportunity',
        'scenario2': 'site-only',
      }

      const audit = this.$store.state?.siteContentAuditsBySiteId?.[this.siteId]

      if (scenario === 'scenario2' && !audit?.[scenarioToKey['scenario1']]) {
        // only consider showing scenario2 dot after scenario 1 is ready
        return false;
      }

      return (
        audit?.status === 'form_submitted'
        && audit?.hasAssociatedDomainLevelCampaign
        && (
          !audit?.[scenarioToKey[scenario]]
        )
        && !this.$router.currentRoute.fullPath.includes(scenarioToPathKey[scenario])
      );
    },
    showWhiteGloveSiteContentAuditOboardingTipOnGoogleButton () {
      const audit = this.$store.state?.siteContentAuditsBySiteId?.[this.siteId]
      return (
        audit?.status === 'form_submitted'
        && audit?.hasAssociatedDomainLevelCampaign
        && (
          !audit?.hasAssociatedScenario1Data
          || !audit?.hasAssociatedScenario2Data
        )
        && !this.googleUser
      );
    }
  },
  mounted () {

  }
}</script>


<style lang="scss" scoped>
@import "../../keyword-research.scss";
.scenarios {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  & > div {
    cursor: pointer;
    border: 1px solid var(--v-grayColor-base);
    border-radius: 3.7px;
    min-width: 290px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--v-cardBg-base);

    &.is-active {
      border: 2px solid var(--v-profileAnchorColor-base);
    }

    img {
      width: auto;
      height: 60px;
    }
    .name {
      color: var(--v-mainColor-base);
      font-size: 0.9rem;
      font-weight: 800;
    }
    .explain {
      text-align: center;
      color: var(--v-mainColor-base);
      font-size: 0.75rem;
    }
  }
}

.white-glove-site-content-audit-oboarding-tip {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  &.google-tip {
    top: 0;
    right: initial;
    left: 480px;
  }
}
</style>