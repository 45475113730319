<template>
<div>
  <div>
    <main-credit-log
      :forAdminPage="forAdminPage"
      :userId="userId"></main-credit-log>
  </div>
  <div class="mt-10">
    <keyword-research-credit-log
      :forAdminPage="forAdminPage"
      :userId="userId"></keyword-research-credit-log>
  </div>
  <div class="mt-10">
    <bulk-credit-log
      :forAdminPage="forAdminPage"
      :userId="userId"></bulk-credit-log>
  </div>
</div>
</template>

<script>
import MainCreditLog from './MainCreditLog.vue';
import KeywordResearchCreditLog from './KeywordResearchCreditLog.vue';
import BulkCreditLog from './BulkCreditLog.vue';

export default {
  props: {
    forAdminPage: {
      type: Boolean,
      default: false,
    },
    userId: {
      default: 0,
      type: Number,
    }
  },
  components: {
    MainCreditLog,
    KeywordResearchCreditLog,
    BulkCreditLog,
  },
  data () {
    return {

    }
  }
}
</script>
