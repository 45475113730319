<template>
<div class="bulk-log-root">
  <div class="title-label d-flex align-center gap10">
    Bulk Runs log
  </div>
  <div class="mt-5 d-flex align-center">
    <div class="flex-grow-1">
      <date-picker v-model="dates" range></date-picker>
      <v-btn rounded outlined
        color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none ml-2"
        @click="loadData"
        :loading="isLoading">
        <span>Load data</span>
      </v-btn>
    </div>
    <div class="total-credits-in-range">
      <span>
        Total credits:&nbsp;
      </span>
      <span>{{ totalCreditsUsed }}</span>
      <v-tooltip top color="transparent">
        <template v-slot:activator="{ on, attrs }">
          <i class="ml-2 material-icons info-icon-size" v-bind="attrs" v-on="on">info</i>
        </template>
        <div class="white-bg-tooltip small-tooltip">
          Total credits used is sum of all the credits used for one report. Total credits used will include report credits (if you are not on an unlimited or teams plan) + EEAT credits + NLP Credits + Watchdog credits + New Al content credits + Paid Al regenerations.  The calculation for total credits used will change based on the dates you have selected.
        </div>
      </v-tooltip>
    </div>
  </div>

  <v-card class="is-fullheight flex flex-column white-common-box mt-5">
    <div class="hscroll-container">
      <div v-if="!records?.length" class="p-4">
        No results for the selected period
      </div>
      <b-table
        v-else
        class="bulk-main-table"
        :data="records"
        :loading="isLoading"
        :paginated="totalRows > perPage"
        :total="totalRows"
        :per-page="perPage"
        :current-page="currentPage"
        @page-change="onPageChange"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        detailed
        detail-key="id"
        @details-open="() => {
          $nextTick(() => {
            makeDetailViewAlign()
          })
        }">
        <template slot-scope="props">
          <b-table-column field="id" label="Bulk run number" sortable class="text-left">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column field="createdAt" label="Date created">
            {{ toLocalDateString(props.row.createdAt) }}
          </b-table-column>
          <b-table-column field="bulkRunCost" label="Bulk Run cost">
            {{ props.row.bulkRunCost }}
          </b-table-column>
          <b-table-column field="nlpCreditsUsedInBulk" label="Total Google NLP credits used">
            {{ props.row.nlpCreditsUsedInBulk }}
          </b-table-column>
          <b-table-column field="eatCreditsUsedInBulk" label="Total EEAT credits used">
            {{ props.row.eatCreditsUsedInBulk }}
          </b-table-column>
          <b-table-column field="aiArticleWordCountCredits" label="AI Writer credits (word count)">
            {{ props.row.aiArticleWordCountCredits }}
          </b-table-column>
          <b-table-column field="aiContentPromptCredits" label="AI writer content prompt cost">
            {{ props.row.aiContentPromptCredits }}
          </b-table-column>
          <b-table-column field="totalBulkCredits" label="Total POP credits used">
            {{ props.row.totalBulkCredits }}
          </b-table-column>
        </template>
        <template slot="detail" slot-scope="props">
          <div class="detail-wrapper">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Report Id</th>
                  <th>Keyword</th>
                  <th>Bulk Run cost</th>
                  <th>Google NLP credits</th>
                  <th>EEAT credits</th>
                  <th>AI Word count cost</th>
                  <th>AI content promopt cost</th>
                  <th>Credits used</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="info in props.row.reportInfo" :key="info.reportId">
                  <td></td>
                  <td>{{ info.reportId }}</td>
                  <td>{{ info.keyword }}</td>
                  <td>-</td>
                  <td>{{ info.nlpCredits }}</td>
                  <td>{{ info.eatCredits }}</td>
                  <td>{{ info.aiWordCountCredits }}</td>
                  <td>{{ info.aiContentPromptCredits }}</td>
                  <td>{{ info.creditsUsed }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </b-table>
      <!-- <table class="top-border-table is-fullwidth invoice-table" style="min-width: 1000px">
        <thead>
          <tr>
            <th>Report ID</th>
            <th>Keyword</th>
            <th>Date</th>
            <th>Report credits</th>
            <th>EEAT credits</th>
            <th>NLP credits</th>
            <th>Watchdog credits used</th>
            <th>AI article count</th>
            <th>New AI content credits</th>
            <th>Free AI regenerations</th>
            <th>Paid AI regenerations</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(r, idx) in records">
            <tr :key="idx">
              <td>{{ r.id }}</td>
              <td>{{ r.keyword }}</td>
              <td>{{ toLocalDateString(r.createdAt) }}</td>
              <td>{{ r.reportCreditsUsed == 0 ? '-' : r.reportCreditsUsed }}</td>
              <td>{{ r.eatCredits == 0 ? '-' : r.eatCredits }}</td>
              <td>{{ r.nlpCredits == 0 ? '-' : r.nlpCredits }}</td>
              <td>{{ r.watchdogCredits == 0 ? '-' : r.watchdogCredits }}</td>
              <td>{{ r.aiArticleCount }}</td>
              <td>{{ r.aiArticleCreditsTotal }}</td>
              <td>{{ r.aiFreeRegenerationUsedTotal }}</td>
              <td>{{ r.aiPaidRegenerationUsedTotal }}</td>
            </tr>
          </template>
        </tbody>
      </table> -->
    </div>
    <!-- <v-btn @click="makeDetailViewAlign">tt</v-btn> -->
  </v-card>
</div>
</template>

<script>

import moment from 'moment'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import { toLocalDateString } from "@/utils";


export default {
  props: {
    forAdminPage: {
      type: Boolean,
      default: false,
    },
    userId: {
      default: 0,
      type: Number,
    }
  },

  components: {
    DatePicker,
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },

  data () {
    return {
      dates: [moment().subtract(1, 'months').toDate(), moment().toDate()],


      isLoading: false,
      totalRows: 0,
      records: [],
      totalCreditsUsed: 0,

      currentPage: 1,
      perPage: 10, // no pagination for now for the total credits
      sortField: "runId",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      searchText: "",

    }
  },
  methods: {
    makeDetailViewAlign () {
      let mainTableAllTh = [...document.querySelectorAll('.bulk-main-table table.table > thead th')]
      let detailTableAllTh = [...document.querySelectorAll('.bulk-main-table .detail-container table th')]
      if (mainTableAllTh && detailTableAllTh) {
        mainTableAllTh.map((th, idx) => {
          let index1 = idx
          while (true) {
            if (detailTableAllTh.length <= index1) break;
            detailTableAllTh[index1].style.width = th.offsetWidth + 'px';
            index1 += mainTableAllTh.length;
          }
        })
      }
    },
    test(data) {
      console.log(data)
    },

    toLocalDateString(dateStr) {
      return toLocalDateString(dateStr);
    },

    onPerPageChange() {
      // this.$nextTick(() => this.loadData());
    },
    onPageChange(page) {
      this.currentPage = page;
      // this.loadData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // this.loadData();
    },
    onFilter() {
      // console.log('filtering')
      // this.$nextTick(() => {
      //   this.loadData();
      // });
    },

    loadData() {
      let startDate = moment(this.dates[0]).startOf('day').toDate();
      let endDate = moment(this.dates[1]).endOf('day').toDate();

      if (this.isLoading) return;
      this.isLoading = true;
      const params = [
        `sortField=${this.sortField}`,
        `sortOrder=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `perPage=${this.perPage}`,
        `filterText=${this.searchText}`,
        `startDate=${startDate.getTime()}`,
        `endDate=${endDate.getTime()}`,
        `userId=${this.forAdminPage ? this.userId : 0}`
      ].join("&");

      this.$store.dispatch('getBulkCreditLog', { params })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          console.log(response.data)
          this.totalRows = response.data.total;
          this.records = response.data.records;
          this.totalCreditsUsed = response.data.totalCredits
        } else {
          this.totalRows = 0;
          this.records = [];
          this.totalCreditsUsed = 0
          
          if (response?.data?.msg) {
            this.$notify({
              group: 'info', type: 'error',
              text: response?.data?.msg
            })
          }

        }
      })
      .finally(() => {
        this.isLoading = false;
      })
    },

  },
  mounted () {
    this.loadData()
  }

}
</script>

<style lang="scss" scoped>
.bulk-log-root {
  .title-label {
    font-size: 18px;
    font-weight: bold;
  }

  .total-credits-in-range { 
    display: flex;
    align-items: center;
    span {
      font-size: 17px;
    }
    & > span:nth-child(1) {
      font-weight: bold;
    }
  }


  ::v-deep {
    .b-table {
      .table thead td, .table thead th {
        border-bottom-width: 1px !important;
        // table-layout: fixed !important;
      }
      th, td {
        vertical-align: middle;
        padding-top: 12px;
        padding-bottom: 12px;
      }

      .linked-number {
        color: var(--v-link15Color-base);
      }
      .detail {
        & > td {
          padding: 0 !important;
        }
        .detail-container {
          padding: 0px !important;
        }
      }
      .detail-wrapper {
        max-height: 300px;
        overflow-y: auto;
        table {
          width: 100%;
          // table-layout: fixed !important;
          th, td {
            vertical-align: middle !important;
            padding-top: 12px !important;
            padding-bottom: 12px !important;
          }
          th:first-child {
            width: 40px;
          }
          th {
            font-weight: 700;
            color: var(--v-mainColor-base);
            border-bottom: 2px solid var(--v-grayColor-base) !important;
          }
          tr:nth-child(odd) td {
            background: var(--v-pink1Color-base);
          }
        }
      }
    }
  }
}
</style>
