<template>

  <div class="modal is-active is-sticky-right silo-reports-checkout-modal">
    <div class="modal-background" @click.stop="$emit('close')"></div>
    <div class="modal-content" style="width: 1200px; max-width: 95vw;">
      <div class="box modal-container mainColor--text">
        <div class="d-flex" style="padding: 24px 32px;">
          <div class="modal-title">{{isEditMode?'Create an order for us':'Checkout order'}}</div>
        </div>
        <div class="main-part">
          <div class="left-part">
            <div class="form-part">

              <template v-if="isEditMode">
                <div class="steps-bar mb-8">
                  <div v-if="topLevelKeyword" :class="{'active': step === 0}">Top-level page</div>
                  <div :class="{'active': step === 1}">Supporting pages</div>
                  <div :class="{'active': step === 2}">Delivery & checkout</div>
                </div>

                <div v-if="step === 0 && topLevelKeyword">
                  <div class="keyword-row top-level">
                    <div class="keyword-type-box">
                      T
                    </div>
                    <div class="keyword-data">
                      <div class="mb-1"><span class="font-weight-bold">{{ topLevelKeyword.keyword }}</span></div>
                      <a :href="getSiloUrl(topLevelKeyword)" target="_blank" rel="nofollow" style="color: #0075FF;">{{ getSiloUrl(topLevelKeyword) }}</a>
                    </div>
                  </div>
                  <div class="mt-8">
                    <span class="font-weight-bold" style="font-size: 16px;">Choose the content delivery for your Top-level page</span>
                  </div>
                  <div class="mt-2">Please select one of the following content delivery types.</div>
                  <div class="mt-8 silo-report-cards-list">
                    <div
                      v-for="report in AVAILAIBLE_TOP_LEVEL_SILO_REPORTS" :key="report.key"
                      :class="['silo-report-card', {'selected': report.key === silo.content_report_order.selected_top_level_option_key}]"
                      @click="updateOrder({
                        selected_top_level_option_key: report.key,
                        ...getEquivalentPackage(report.key, 'supporting')
                          ?{selected_supporting_option_key: getEquivalentPackage(report.key, 'supporting').key}
                          :{}
                      })"
                    >
                      <div class="silo-report-card-image">
                        <img :src="report.img" />
                      </div>
                      <div class="mb-3"><span class="font-weight-bold">${{ report.price }} USD</span></div>
                      <div class="mb-3"><span class="font-weight-bold">{{ report.label }}</span></div>
                      <div>{{ report.description }}</div>
                      <div class="check-mark">
                        <v-icon
                          small
                        >
                          done
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else-if="step === 1">
                  <div class="mb-6">
                    <span class="font-weight-bold" style="font-size: 16px;">Supporting pages you’ve selected ({{ supportingKeywords.length }})</span>
                  </div>
                  <div class="mb-2">
                    <div
                      v-for="keyword in supportingKeywords.slice(0, showAllSupportingKeywords?supportingKeywords.length:2)" :key="keyword.id"
                      class="keyword-row supporting mb-4"
                    >
                      <div class="keyword-type-box">
                        S
                      </div>
                      <div class="keyword-data">
                        <div class="mb-1"><span class="font-weight-bold">{{ keyword.keyword }}</span></div>
                        <a :href="getSiloUrl(keyword)" target="_blank" rel="nofollow" style="color: #0075FF;">{{ getSiloUrl(keyword) }}</a>
                      </div>
                      <div class="action">
                        <v-btn
                          v-if="supportingKeywords.length > 3"
                          icon
                          small
                          color="red"
                        >
                          <i class="material-icons-outlined">delete</i>
                        </v-btn>
                      </div>
                    </div>
                    <a 
                      href="#"
                      class="supporting-pages-toggle mb-2"
                      @click.prevent="showAllSupportingKeywords = !showAllSupportingKeywords"
                    >
                      {{showAllSupportingKeywords?'Show less pages':'+ Show all pages...'}}
                    </a>
                  </div>
                  <div class="mt-8">
                    <span class="font-weight-bold" style="font-size: 16px;">Choose the content delivery for your Supporting pages</span>
                  </div>
                  <div class="mt-2">Please select one of the following content delivery types.</div>
                  <div class="mt-8 silo-report-cards-list">
                    <div
                      v-for="report in AVAILAIBLE_SUPPORTING_SILO_REPORTS" :key="report.key"
                      :class="['silo-report-card', {'selected': report.key === silo.content_report_order.selected_supporting_option_key}]"
                      @click="updateOrder({selected_supporting_option_key: report.key})"
                    >
                      <div class="silo-report-card-image">
                        <img :src="report.img" />
                      </div>  
                      <div class="mb-3"><span class="font-weight-bold">${{ report.price }} USD</span></div>
                      <div class="mb-3"><span class="font-weight-bold">{{ report.label }}</span></div>
                      <div>{{ report.description }}</div>
                      <div class="check-mark">
                        <v-icon
                          small
                        >
                          done
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else-if="step === 2">
                  <div class="pb-2"><span class="font-weight-bold">Delivery time & checkout</span></div>
                  <div class="pb-8">You can select 3 silos for 30 days, 3 silos for 60 days and 3 silos for 90 days.</div>

                  <b-dropdown aria-role="list" class="delivery-period-dropdown">
                    <template #trigger>
                      <b-button
                        class="delivery-period-dropdown-button"
                        type="mainColor--text"
                        :label="
                          silo.content_report_order.delivery_period_days
                            ?`Selected delivery period: ${silo.content_report_order.delivery_period_days} days`
                            :'Select delivery period'
                        "
                        icon-left="calendar" />
                    </template>

                    <b-dropdown-item custom aria-role="menuitem">
                      <span class="font-weight-bold mainColor--text">Select period</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-for="option in [30, 60, 90]" :key="option"
                      aria-role="listitem"
                      :disabled="!availableDeliveryPeriods.includes(option) && silo.content_report_order.delivery_period_days !== option"
                      @click="updateOrder({delivery_period_days: option})"
                    >
                      <b-icon
                        :icon="silo.content_report_order.delivery_period_days === option?'check':''"
                        type="is-success"
                        size="is-small"
                      ></b-icon>
                      {{ `${option} days` + (!availableDeliveryPeriods.includes(option) && silo.content_report_order.delivery_period_days !== option?' (Unavailable)':'') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              
              <template v-if="silo?.content_report_order?.order_status === 'in_checkout'">
                In checkout
              </template>

              <template v-if="['in_cart', 'in_cart_checkout'].includes(silo?.content_report_order?.order_status)">
                Order was added to cart.
                <br /><br />
                <a href="#" @click.prevent="$store.commit('setShowSiloContentReportsOrdersCart', true); $emit('close');">Open cart</a>
              </template>

            </div>
            <div class="form-controls-part">
              <template v-if="isEditMode">
                <div>
                  <v-btn
                    v-if="step > (topLevelKeyword ? 0 : 1)"
                    rounded
                    outlined
                    color="mainColor"
                    class="text-none"
                    @click="step -= 1"
                  >
                    <i class="material-icons" style="margin-right: 10px;">arrow_back</i>
                    <span>Back</span>
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    v-if="step < 2"
                    rounded
                    color="profileAnchorColor"
                    class="text-none whiteColor--text"
                    :disabled="!isStepValid(step)"
                    @click="step += 1"
                  >
                    <span>Next step</span>
                  </v-btn>
                  <template
                    v-else-if="step === 2"
                  >
                    <v-btn
                      rounded
                      outlined
                      color="mainColor"
                      class="text-none buy-now-btn"
                      :disabled="!isStepValid(2)"
                      @click="buyNow()"
                    >
                      <span>Buy now</span>
                    </v-btn>
                    <v-btn
                      rounded
                      color="profileAnchorColor"
                      class="text-none whiteColor--text"
                      :disabled="!isStepValid(2)"
                      @click="updateOrder({set_in_cart: true})"
                    >
                      <span>Add to cart & select more silos</span>
                    </v-btn>
                  </template>
                </div>
              </template>
              <template v-else-if="['in_checkout', 'in_cart'].includes(silo?.content_report_order?.order_status)">
                <div>
                  <v-btn
                    rounded
                    outlined
                    class="text-none"
                    @click="updateOrder({set_user_editing: true})"
                  >
                    <i class="material-icons" style="margin-right: 10px;">arrow_back</i>
                    <span>Edit</span>
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    rounded
                    outlined
                    color="mainColor"
                    class="text-none"
                    @click="buyNow()"
                  >
                    <span>Buy now</span>
                  </v-btn>
                </div>
              </template>
            </div>
          </div>
          <div class="right-part">
            <div class="sub-title mainColor--text mb-6">
              Summary
            </div>

            <div v-if="topLevelKeyword && selectedTopLevelOption" class="summary-card mainColor--text mb-4">
              <div class="main">
                <v-icon class="main-icon">article</v-icon>
                <div class="main-content">
                  <div class="option-title mb-2">Top-level page report</div>
                  <div class="option-price">${{ selectedTopLevelOption.price }}.00 USD</div>
                </div>
              </div>
              <table v-if="isEditMode && step === 0" class="detail mt-6" style="width: 100%;">
                <thead>
                  <tr>
                    <td>Report</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="font-weight-bold">{{ selectedTopLevelOption.label }}</span></td>
                    <td style="text-transform: uppercase;">${{ selectedTopLevelOption.price }}.00 USD</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="selectedSupportingOption" class="summary-card mainColor--text mb-4">
              <div class="main">
                <v-icon class="main-icon">article</v-icon>
                <div class="main-content">
                  <div class="option-title mb-2">Supporting pages reports</div>
                  <div class="option-price">${{ selectedSupportingOption.price * supportingKeywords.length }}.00 USD</div>
                </div>
              </div>

              <table v-if="isEditMode && step === 1" class="detail mt-6" style="width: 100%;">
                <thead>
                  <tr>
                    <td>Report</td>
                    <td>Quantity</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="mb-2"><span class="font-weight-bold">{{ selectedSupportingOption.label }}</span></div>
                      ${{ selectedSupportingOption.price }}.00 USD
                    </td>
                    <td>
                      {{ supportingKeywords.length }} pages
                    </td>
                    <td style="text-transform: uppercase;">${{ selectedSupportingOption.price * supportingKeywords.length }}.00 USD</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="!isEditMode || step === 2" class="total-amount-card mb-4">
              <v-icon class="card-icon mb-4">article</v-icon>
              <div class="card-title mb-4">Total Amount</div>
              <div class="card-price">${{ (topLevelKeyword ? selectedTopLevelOption.price : 0) + (selectedSupportingOption.price * supportingKeywords.length) }}.00 USD</div>
            </div>
              
          </div>
        </div>
      </div>
      <button class="custom-modal-close-btn" aria-label="close" @click="$emit('close')"><i class="material-icons" style="font-size: 1.7rem;">close</i></button>
    </div>
  </div>

</template>

<script>
import { getPureServicePkgName } from '@/utils';
import {
  whiteGlovePlans,
} from "@/components/plans_constants"


export default {
  props: ['silo', 'keywords', 'isEditMode', 'refreshData'],
  components: {

  },
  data() {
    return {
      step: 0,
      packages: [],
      availableDeliveryPeriods: [],
      showAllSupportingKeywords: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    AVAILAIBLE_TOP_LEVEL_SILO_REPORTS() {
      return this.packages.filter(p => p.type === 'top_level');
    },
    AVAILAIBLE_SUPPORTING_SILO_REPORTS() {
      return this.packages.filter(p => p.type === 'supporting');
    },
    selectedTopLevelOption() {
      return this.AVAILAIBLE_TOP_LEVEL_SILO_REPORTS.filter(p => p.key === this.silo.content_report_order.selected_top_level_option_key)[0];
    },
    selectedSupportingOption() {
      return this.AVAILAIBLE_SUPPORTING_SILO_REPORTS.filter(p => p.key === this.silo.content_report_order.selected_supporting_option_key)[0];
    },
    topLevelKeyword() {
      return this.keywords.filter(keyword => keyword.keywordData.isTopLevelKeyword)[0];
    },
    supportingKeywords() {
      return this.keywords.filter(keyword => keyword.keywordData.isSupportingKeyword);
    },
  },
  methods: {
    getSiloUrl(siloKeyword) {
      return siloKeyword.keywordData.page || siloKeyword.keywordData.currentRankingUrl;
    },
    isStepValid(step) {
      if (step === 0 && this.topLevelKeyword) {
        return Boolean(this.silo.content_report_order.selected_top_level_option_key)
      }
      if (step === 0 && !this.topLevelKeyword) {
        return true
      }
      if (step === 1) {
        return this.isStepValid(step - 1) && Boolean(this.silo.content_report_order.selected_supporting_option_key)
      }
      if (step === 2) {
        return this.isStepValid(step - 1) && Boolean(this.silo.content_report_order.delivery_period_days)
      }
      return false;
    },
    getEquivalentPackage(package_key, target_type) {
      const package_key_without_prefix =  package_key.replace('TOP_LEVEL_', '').replace('SUPPORTING_', '')
      return this.packages
        .filter(p => p.type === target_type)
        .find(p => p.key.replace('TOP_LEVEL_', '').replace('SUPPORTING_', '') === package_key_without_prefix);
    },
    buyNow() {
      this.$store.commit('showLoading');
      this.$store.dispatch('createStripePayment', {
        packageType: 'SILO_CONTENT_REPORTS_PURCHASE',
        userID: this.user.id,
        siloId: this.silo.id,
      })
      .then(function (response) {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_self')
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
    updateOrder(payload) {
      this.$store.commit('showLoading');
      return this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: payload
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          if (payload.set_in_cart) {
            const { siteId, campaignId } = this.$route.params
            this.$store.commit('triggerRefreshSiloContentReportsOrdersCart', true);
            this.$router.push(`/sites/${siteId}/campaign/${campaignId}/silo`);
            this.$emit('close');
          } else {
            return this.refreshData()
          }
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
  },
  mounted () {

    if (!this.topLevelKeyword) {
      this.step = 1
    }

    this.$store.commit('showLoading')
    this.$store.dispatch('getAvailableSiloContentReportPackages')
    .then(response => {
      if (response.data && response.data.status == 'SUCCESS') {
        this.packages = response.data.packages;
        return Promise.resolve()
      }
    })
    .then(() => this.$store.dispatch('getAvailableSiloContentReportDeliveryOptions', {
      siteId: this.silo.siteId
    }))
    .then(response => {
      if (response.data && response.data.status == 'SUCCESS') {
        this.availableDeliveryPeriods = response.data.availableDeliveryPeriods;
        return Promise.resolve()
      }
    })
    .then(() => {
      const defaultValues = {}
      if (!this.silo.content_report_order.selected_top_level_option_key) {
        defaultValues.selected_top_level_option_key = this.AVAILAIBLE_TOP_LEVEL_SILO_REPORTS[0].key
      }
      if (!this.silo.content_report_order.selected_supporting_option_key) {
        defaultValues.selected_supporting_option_key = this.AVAILAIBLE_SUPPORTING_SILO_REPORTS[0].key
      }
      if (Object.entries(defaultValues).length) {
        return this.updateOrder(defaultValues)
      }
      return Promise.resolve()
    })
    .finally(() => {
      this.$store.commit('hideLoading')
    })
  }
};
</script>

<style lang="scss" scoped>
.silo-reports-checkout-modal {

  overflow: hidden;

  .modal-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .steps-bar {
    display: inline-flex;
    flex-direction: row;
    gap: 12px;
    padding: 8px;
    border: 1px solid #CCCCCC;
    border-radius: 8px;
    cursor: default;

    div {
      flex: 1;
      padding: 12px 16px;
      font-size: 13px;
      border-radius: 7px;
      text-align: center;
      font-weight: bold;
      white-space: nowrap;

      &.active {
        background: #FFEDDD80;
        color: #FF5700;

      }
    }
  }

  .keyword-row {
    display: flex;
    flex-direction: row;
    gap: 12px;

    .keyword-type-box {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 35px;
      border-radius: 8px;
      text-align: center;
      font-weight: bold;
    }

    .keyword-data {
      flex: 1;
      font-size: 16px;
    }

    .action {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.top-level {
      .keyword-type-box {
        background: #D6FBEE;
        color: #007438;
      }
    }
    &.supporting {
      .keyword-type-box {
        background: #FFE1F3;
        color: #EF34A4;
      }
    }
  }

  .supporting-pages-toggle {
    color: #FF5700;
    font-weight: bold;
  }

  .main-part {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: stretch;
    overflow: hidden;

    border-top: 1px solid #CCCCCC;

    .left-part {
      border-right: 1px solid #CCCCCC;
      display:flex;
      flex-direction: column;
      flex: 1;

      .form-part {
        flex: 1;
        padding: 32px;
        border-bottom: 1px solid #CCCCCC;
        overflow-y: auto;
      }
      .form-controls-part {
        padding: 24px 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
          display: flex;
          flex-direction: row;
          gap: 8px;
        }

        .buy-now-btn {
          border: none;
          font-weight: bold;
          letter-spacing: normal;
          text-decoration: underline;
          text-decoration-thickness: 1.5px;
        }
      }
    }

    .right-part {
      flex-basis: 37%;
      padding: 32px;

      .sub-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 37.65px;
      }
    }
  }
}

.silo-report-cards-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.silo-report-card {
  cursor: pointer;
  position: relative;
  flex: 1;
  flex-basis: 30%;
  flex-grow: 0;
  padding: 16px 16px 24px 16px;
  border-radius: 3px;

  border: 1px solid #CCCCCC;

  .check-mark {
    position: absolute;
    display:flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    right: 8px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 1px solid #CCCCCC;
    background: #ffffff;
    border-radius: 100px;
    
    .v-icon {
      display: none;
    }
  }

  &.selected {
    border-color: #FF5700;

    .check-mark {
      border: 1px solid #FF5700;
      background: #FF5700;
      .v-icon {
        display: inline-flex;
        color: #fff;
      }
    }
  }
}

.delivery-period-dropdown {
  .delivery-period-dropdown-button {
    font-weight: 700;
    border-width: 1.3px;
    border-color: #014751;
  }
}

.summary-card {
  background: #F8F8F8;
  padding: 16px;

  .main {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .main-icon {
      color: #9DA2A8;
    }
    
    .main-content {
      flex: 1;

      .option-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
      }
      .option-price {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }

  .detail {
    td {
      padding: 12px 4px;
    }
    thead {
      font-weight: bold;
      border-top: 1px solid #DDD;
      border-bottom: 1px solid #DDD;
      text-transform: uppercase;

      td {
        font-size: 10px;
      }
    }
  }
}

.total-amount-card {
  background: rgba(255, 237, 221, 0.5);
  padding: 20px 12px;
  text-align: center;

  .card-icon {
    color: #FF5700;
  }

  .card-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  .card-price {
    font-size: 24px;
    font-weight: bold;
  }

}
</style>
