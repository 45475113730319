<template>
<div class="tool-card pa-5">
  <div class="top-part">
    <span class="icon-part">
      <span class="material-icons-outlined">vpn_key</span>
    </span>
    <span class="name">Keyword Research</span>
  </div>
  <!-- NOTE: The tab selector code below is commented-out in order to hide the Page tab -->

  <div class="mt-5">

    <div class="custom-tabs" v-if="availableTabs.length > 1">
      <div
        v-for="(item, idx) in availableTabs"
        :key="idx"
        :class="{ 'is-active': currentTab == item.key }"
        @click="() => { currentTab = item.key }"
      >
        {{ item.label }}
      </div>
    </div>
    <v-text-field outlined dense
      v-model="searchTerm"
      hide-details="auto"
      label="Search"
      color="greenColor"
      class="mt-2"
      v-if="currentTab == 'Pages' && pageLevelCampaigns.length > 0">
    </v-text-field>
  </div>

  <template v-if="currentTab == 'Domain'">
    <div class="flex-grow-1 pages-wrapper mt-3" style="position: relative;">
      <template v-if="domainLevelCampaign">
        <div
          v-if="showWhiteGloveSiteContentAuditOboardingTip"
          class="white-glove-site-content-audit-oboarding-tip"
        >
          <v-tooltip right color="transparent">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <pulsating-dot />
              </div>
            </template>
            <div class="white-bg-tooltip small-tooltip">
              Click on Keyword Insight to open the dashboard and connect your GSC to POP.
            </div>
          </v-tooltip>  
        </div>
        <div v-for="report in domainLevelCampaignLatestGeneratedReports" :key="report.data.id" class="page-item" @click="() => {
          $router.push(reportTypeToRoute(report._type))
        }">
          <div class="flex-grow-1">
            <div class="name">{{reportTypeToLabel(report._type)}}</div>
            <div class="time">
              {{ toLocalDateTimeString(report.data.created_at) }}
            </div>
          </div>
          <div class="action">
            <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
          </div>
        </div>
      </template>
      <div v-if="domainLevelCampaigns.length == 0" class="no-project-block">
        <div>
          <img src="/static/empty-folder.png" />
          <div>
            You haven't created any <br />
            keyword reports for this project yet
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 d-flex align-center justify-space-between">
      <v-btn v-if="!$store.getters.isDemoUser" rounded small color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none"
        :disabled="!!domainLevelCampaign"
        @click="() => {
          // $router.push(`/sites/${site.id}/newpage`)
          $refs.createDomainCampaignModalRef.showModalAction();
        }">
        <span>Create new</span>
      </v-btn>
      <!-- <span class="how-many" @click="() => {
        $router.push(`/sites/${site.id}/pages?tab=keyword-research`)
      }">
        {{ domainLevelCampaigns.length }} {{ domainLevelCampaigns.length > 1 ? 'domains' : 'domain' }}
      </span> -->
    </div>
  </template>

  <template v-else-if="currentTab == 'Silos'">
    <div class="flex-grow-1 pages-wrapper mt-3">
      <template v-if="domainLevelCampaign">
        <div v-for="silo in silos" :key="silo.id" class="page-item" @click="() => {
          $router.push(`/sites/${siteId}/campaign/${domainLevelCampaign.id}/silo?id=${silo.id}`)
        }">
          <div class="flex-grow-1">
            <div class="name">{{ silo.name }}</div>
            <div class="time">
              {{ toLocalDateTimeString(silo.createdAt) }}
            </div>
          </div>
          <div class="action">
            <i class="material-icons-outlined" @click="() => {
              $router.push(`/sites/${siteId}/campaign/${domainLevelCampaign.id}/silo?id=${silo.id}`)
            }">arrow_circle_right</i>
          </div>
        </div>
      </template>
    </div>
  </template>

  <template v-else-if="currentTab == 'Pages'">
    <div class="flex-grow-1 pages-wrapper mt-3">
      <div v-for="c in filteredPageLevelCampaigns" :key="c.id"  class="page-item" @click="() => {
          $router.push(`/sites/${ siteId }/campaign/${ c.id }`)
        }">
        <div class="flex-grow-1">
          <div class="name">{{ c.campaignName }}</div>
          <div class="time">
            {{ toLocalDateTimeString(c.createdAt) }}
          </div>
        </div>
        <div class="action">
          <i class="material-icons-outlined" @click="() => {}">arrow_circle_right</i>
        </div>
      </div>
      <div v-if="pageLevelCampaigns.length == 0" class="no-project-block">
        <div>
          <img src="/static/empty-folder.png" />
          <div>
            You haven't created any <br />
            keyword reports for this project yet
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 d-flex align-center justify-space-between">
      <v-btn v-if="!$store.getters.isDemoUser" rounded small color="profileAnchorColor"
        class="button-with-icon whiteColor--text text-none"
        @click="() => {
          // $router.push(`/sites/${site.id}/newpage`)
          $refs.createPageCampaignModalRef.showModalAction()
        }">
        <span>Create new</span>
      </v-btn>
      <span class="how-many" @click="() => {
        $router.push(`/sites/${site.id}/pages?tab=keyword-research`)
      }">
        {{ pageLevelCampaigns.length }} {{ pageLevelCampaigns.length > 1 ? 'pages' : 'page' }}
      </span>
    </div>
  </template>

  <create-domain-level-campaign-modal ref="createDomainCampaignModalRef"></create-domain-level-campaign-modal>
  <create-page-level-campaign-modal ref="createPageCampaignModalRef"></create-page-level-campaign-modal>

</div>
</template>

<script>
import { mixin } from '@/components/mixin'
import { getPureServicePkgName } from '@/utils'
import { SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS } from "@/components/plans_constants";

import CreateDomainLevelCampaignModal from '@/components/Tools/CreateDomainLevelCampaignModal.vue'
import CreatePageLevelCampaignModal from '@/components/Tools/CreatePageLevelCampaignModal.vue'
import PulsatingDot from '../../common/PulsatingDot.vue';


export default {
  mixins: [mixin, ],
  components: {
    CreateDomainLevelCampaignModal,
    CreatePageLevelCampaignModal,
    PulsatingDot,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    selectedSubAccountRelation () {
      return this.$store.state.selectedSubAccountRelation
    },
    isSpecialAdminUser () {
      return this.$store.getters.isSpecialAdminUser
    },
    isSpecialOwnerSelected () {
      return this.$store.getters.isSpecialOwnerSelected
    },
    hasSiloPermission () {
      if (this.selectedSubAccountRelation) {
        if (this.isSpecialOwnerSelected) {
          return true
        } else {
          return this.selectedSubAccountRelation.grantedDevModeTools.includes('silo')
        }
      } else if (this.isSpecialAdminUser) {
        return true
      }
      return this.user.grantedDevModeTools.includes('silo')
    },
    siteId () {
      return parseInt(this.$route.params.siteId)
    },
    domainLevelCampaigns () {
      return this.campaigns.filter(item => item.isDomainLevelCampaign)
    },
    pageLevelCampaigns () {
      return this.campaigns.filter(item => !item.isDomainLevelCampaign)
    },
    domainLevelCampaign () {
      if (this.domainLevelCampaigns.length > 0) {
        return this.domainLevelCampaigns[0]
      }
      return null
    },
    filteredPageLevelCampaigns () {
      return this.pageLevelCampaigns.filter(item => item.campaignName.includes(this.searchTerm))
    },
    availableTabs () {
      return [
      {key: 'Domain', label:'Keyword Research tools'},
      ...(this.hasSiloPermission?[{key: 'Silos', label:'Your Silos'}]:[]),
      /*{key: 'Pages', label:'Pages'}*/
      ]
    },
    showWhiteGloveSiteContentAuditOboardingTip () {
      const audit = this.$store.state?.siteContentAuditsBySiteId?.[this.siteId]
      return (
        audit?.status === 'form_submitted'
        && audit?.hasAssociatedDomainLevelCampaign
        && (
          !audit?.hasAssociatedScenario1Data
          || !audit?.hasAssociatedScenario2Data
        )
      );
    }
  },
  watch: {
    siteId: function (newVal, oldVal) {
      this.loadCampaigns()
    },
    domainLevelCampaign: function (newVal, oldVal) {
      if (!newVal) {
        this.domainLevelCampaignLatestGeneratedReports = []
        this.silos = []
      } else if (newVal && newVal != oldVal) {
        this.domainLevelCampaignLatestGeneratedReports = []
        this.silos = []
        this.loadCampaignLatestGeneratedReports()
        this.loadSilos()
      }
    },
    '$store.state.triggerCreateDomainCampaignModal': function () {
      this.$refs.createDomainCampaignModalRef.showModalAction();
    }
  },
  data () {
    return {
      currentTab: 'Domain', // site / page
      searchTerm: '',
      
      campaigns: [],

      domainLevelCampaignLatestGeneratedReports: [],
      silos: [],

    }
  },
  methods: {
    loadCampaigns () {
      this.$store.dispatch('getCampaignsForSite', this.siteId)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.campaigns = response.data.campaigns
        }
      })
    },
    loadCampaignLatestGeneratedReports () {
      this.$store.dispatch('getCampaignLatestGeneratedReports', this.domainLevelCampaign.id)
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.domainLevelCampaignLatestGeneratedReports = response.data.result?.length
            ? response.data.result
            : [
              {
                '_type': 'KeywordResearch',
                'data': {
                  id: undefined,
                  created_at: this.domainLevelCampaign.createdAt
                },
              }
            ]
        }
      })
    },
    loadSilos () {
      this.$store.dispatch('getSilos', { campaignId: this.domainLevelCampaign.id, paginate: 0 })
      .then((response) => {
        this.silos = response.data.data;
      })
    },
    reportTypeToLabel (reportType) {
      const accountType = this.selectedSubAccountRelation.ownerAccountType || this.user.accountType
      
      // NOTE: this should be removed once more keyword research tools are open to users
      const trimKeywordStrategyLabel = SUBSCRIPTIONS_WITH_ACCESS_TO_KEYWORD_RESEARCH_TOOLS.map(x => getPureServicePkgName(x)).includes(getPureServicePkgName(accountType))
      
      return ({
        'KeywordResearch': 'Keyword Insight',
        'SupportKeywordResearch': 'Keyword strategy - Existing content - Supporting keywords',
        'KeywordWizardResearch:PAGE_OPPORTUNITY': 'Keyword strategy - Existing content - Pages',
        'KeywordWizardResearch:SITE_ONLY_EXIST': 'Keyword strategy - Existing content - Top-level keywords',
        'TopLevelSupportKeywordResearch': trimKeywordStrategyLabel? 'Keyword strategy' : 'Keyword strategy - New content',
        'SiloReport': 'Silo Report',
      })[reportType] || reportType
    },
    reportTypeToRoute (reportType) {
      return ({
        'KeywordResearch': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-insight`,
        'SupportKeywordResearch': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/existing-content/supporting-keyword-research`,
        'KeywordWizardResearch:PAGE_OPPORTUNITY': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/existing-content/page-opportunity`,
        'KeywordWizardResearch:SITE_ONLY_EXIST': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/existing-content/site-only`,
        'TopLevelSupportKeywordResearch': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-wizard/new-content/keyword-only`,
        'SiloReport': `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/silo`,
      })[reportType] || `/sites/${ this.siteId }/campaign/${ this.domainLevelCampaign.id }/keyword-insight`  // default
    },
  },
  mounted () {
    this.loadCampaigns()    
  }
}
</script>

<style lang="scss" scoped>
.custom-tabs {
  display: flex;
  background: var(--v-gray7Color-base);
  border-radius: 10px;
  padding: 3px;
  & > div {
    flex-basis: 35%;
    text-align: center;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    color: var(--v-mainColor-base);
    font-weight: 700;

    &:first-child {
      flex: 1;
    }

    &.is-active {
      background: var(--v-whiteColor-base);
      box-shadow: 0px 3px 4px -1px #00000014;
    }
  }
}

.white-glove-site-content-audit-oboarding-tip {
  position: absolute;
  top: 32px;
  right: 40px;
}
</style>
