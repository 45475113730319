<template>
    <div class="ring-container">
        <div class="ringring" :style="`border-color: ${color || '#F73030'}`"></div>
        <div class="circle" :style="`background-color: ${color || '#F73030'}`"></div>
    </div>
</template>

<script>
export default {
    props: ['color']
}
</script>

<style lang="scss" scoped>
.ring-container {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 5px;
}

.ringring {
    border: 3px solid #F73030;
    -webkit-border-radius: 30px;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-animation: pulsate 1s ease-out;
    -webkit-animation-iteration-count: infinite; 
    opacity: 0.0
}
@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
</style>