<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <div class="field">
            <div class="control flex justify-end"></div>
          </div>
          <div class="white-common-box pa-5">
            <h3 class="subtitle-font mainColor--text">Create coupon</h3>
            <p v-if="couponCodeErrMsg" class="has-text-centered">
              {{ couponCodeErrMsg }}
            </p>
            <div class="field is-horizontal">
              <div class="field-label">
                <label class="label">Code</label>
              </div>
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control is-expanded">
                    <input
                      type="text"
                      class="input"
                      v-model="currentCouponCode"
                    />
                  </div>
                  <div class="control">
                    <v-btn
                      color="greenColor"
                      class="button-with-icon text-none whiteColor--text"
                      @click="showAutoGenModal = true"
                    >
                      Auto Generate
                    </v-btn>
                    <v-btn
                      color="mainColor"
                      class="button-with-icon text-none whiteColor--text"
                      v-clipboard:copy="currentCouponCode"
                      v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                      v-clipboard:error="() => $notify({ group: 'info', type: 'success', text: 'can not copy to clipboard' })"
                    >
                      Copy to clipboard
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label">
                <label for="coupon-type" class="label">Coupon Type</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select id="coupon-type" v-model="currentCouponType">
                        <option value="Credits">Credits</option>
                        <option value="Period Free">Free For Days</option>
                        <option value="Credit Percentage Off">Credit Percentage Off</option>
                        <option value="Percent Off">Subscription Percent Off</option>
                        <option value="Amount Off">Subscription Amount Off</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal"
              v-show="currentCouponType === 'Credits'">
              <div class="field-label">
                <label for="coupon-credits" class="label">Credits</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      id="coupon-credits"
                      type="number"
                      min="1" max="1000"
                      step="1"
                      class="input"
                      v-model="currentCouponCredits" />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal"
              v-if="currentCouponType === 'Period Free'"
            >
              <div class="field-label">
                <label for="coupon-product" class="label">Product</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select v-model="currentCouponProduct">
                        <option value="BASIC_FREE">Basic free</option>
                        <option value="PREMIUM_FREE">Premium free</option>
                        <option value="platinum_free">Unlimited free</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="field is-horizontal"
              v-if="currentCouponType === 'Period Free'"
            >
              <div class="field-label">
                <label for="coupon-credits" class="label">Days Of Free</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      id="coupon-credits"
                      type="number"
                      min="1"
                      max="1000"
                      step="1"
                      class="input"
                      v-model="currentCouponFreeDays"
                    />
                  </div>
                </div>
              </div>
            </div>

            
            <div
              class="field is-horizontal"
              v-if="['Percent Off', 'Amount Off', 'Credit Percentage Off'].includes(currentCouponType)"
            >
              <div class="field-label">
                <label for="percent-off" class="label">
                  <template v-if="['Percent Off', 'Credit Percentage Off'].includes(currentCouponType)">Percentage Off</template>
                  <template v-else>Discount</template>
                </label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      id="percent-off"
                      type="number"
                      min="0"
                      max="100"
                      step="1"
                      class="input"
                      v-model.number="currentCouponAmountOff"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="field is-horizontal"
              v-show="currentCouponType === 'Amount Off' || currentCouponType === 'Percent Off'"
            >
              <div class="field-label">
                <label for="coupon-product" class="label">Product</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <div class="select is-fullwidth">
                      <select
                        id="coupon-product"
                        v-model="currentCouponProduct"
                      >
                        <!-- <option value="SERVICE_PKG_A">{{ serviceLabel('SERVICE_PKG_A') }}</option> -->
                        <!-- <option value="SERVICE_PKG_B" disabled="disabled">Old {{ serviceLabel('SERVICE_PKG_B') }} (Do not use)</option> -->

                        <!-- <option value="SERVICE_PKG_B2">{{ serviceLabel('SERVICE_PKG_B2') }}</option>
                        <option value="SERVICE_PKG_C">{{ serviceLabel('SERVICE_PKG_C') }}</option>
                        <option value="SERVICE_PKG_D">{{ serviceLabel('SERVICE_PKG_D') }}</option>
                        <option value="SERVICE_PKG_E">{{ serviceLabel('SERVICE_PKG_E') }}</option>
                        <option value="SERVICE_PKG_F">{{ serviceLabel('SERVICE_PKG_F') }}</option>
                        <option value="SERVICE_PKG_G">{{ serviceLabel('SERVICE_PKG_G') }}</option>
                        <option value="SERVICE_PKG_H">{{ serviceLabel('SERVICE_PKG_H') }}</option>
                        <option value="SERVICE_PKG_I">{{ serviceLabel('SERVICE_PKG_I') }}</option>
                        <option value="SERVICE_PKG_J">{{ serviceLabel('SERVICE_PKG_J') }}</option>
                        <option value="SERVICE_PKG_K">{{ serviceLabel('SERVICE_PKG_K') }}</option> -->

                        <!-- <option value="P1__SERVICE_PKG_B2">{{
                          serviceLabel("P1__SERVICE_PKG_B2")
                        }}</option>
                        <option value="P1__SERVICE_PKG_C">{{
                          serviceLabel("P1__SERVICE_PKG_C")
                        }}</option>
                        <option value="P1__SERVICE_PKG_D">{{
                          serviceLabel("P1__SERVICE_PKG_D")
                        }}</option>
                        <option value="P1__SERVICE_PKG_E">{{
                          serviceLabel("P1__SERVICE_PKG_E")
                        }}</option>
                        <option value="P1__SERVICE_PKG_F">{{
                          serviceLabel("P1__SERVICE_PKG_F")
                        }}</option>
                        <option value="P1__SERVICE_PKG_G">{{
                          serviceLabel("P1__SERVICE_PKG_G")
                        }}</option>
                        <option value="P1__SERVICE_PKG_H">{{
                          serviceLabel("P1__SERVICE_PKG_H")
                        }}</option>
                        <option value="P1__SERVICE_PKG_I">{{
                          serviceLabel("P1__SERVICE_PKG_I")
                        }}</option>
                        <option value="P1__SERVICE_PKG_J">{{
                          serviceLabel("P1__SERVICE_PKG_J")
                        }}</option>
                        <option value="P1__SERVICE_PKG_K">{{
                          serviceLabel("P1__SERVICE_PKG_K")
                        }}</option> -->

                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_B2`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_B2`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_C`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_C`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_D`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_D`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_E`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_E`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_AA`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_AA`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_BB`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_BB`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}EXTRA_SEAT_AA`">{{
                          serviceLabel(`${DEFAULT_PREFIX}EXTRA_SEAT_AA`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}EXTRA_SEAT_BB`">{{
                          serviceLabel(`${DEFAULT_PREFIX}EXTRA_SEAT_BB`)
                        }}</option>
                        <!-- custom plans -->
                        <option value="SERVICE_PKG_L">{{
                          serviceLabel("SERVICE_PKG_L")
                        }}</option>
                        <option value="SERVICE_PKG_M">{{
                          serviceLabel("SERVICE_PKG_M")
                        }}</option>

                        <option :value="`${DEFAULT_PREFIX}SERVICE_PKG_CC`">{{
                          serviceLabel(`${DEFAULT_PREFIX}SERVICE_PKG_CC`)
                        }}</option>
                        <option :value="`${DEFAULT_PREFIX}EXTRA_SEAT_CC`">{{
                          serviceLabel(`${DEFAULT_PREFIX}EXTRA_SEAT_CC`)
                        }}</option>

                        <option :disabled="currentCouponType != 'Percent Off'"
                          value="ANY">All Plans</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentCouponType === 'Amount Off' || currentCouponType === 'Percent Off'">
              <div class="field is-horizontal">
                <div class="field-label">
                  <label for="coupon-product" class="label">Origin price</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input disabled
                        type="number"
                        class="input"
                        :value="PRICE_OF_EACH_VERSIONING[`${currentCouponProduct}`]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div
              class="field is-horizontal"
              v-show="currentCouponType === 'Amount Off'"
            >
              <div class="field-label">
                <label for="coupon-product" class="label">Affiliates</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div style="z-index: 99">
                    <multiselect
                      v-model="currentCouponAffiliates"
                      track-by="id"
                      placeholder="Search for affiliates"
                      label="email"
                      :options="affiliates"
                      :multiple="true"
                      :taggable="true"
                      @tag="setCurrentCouponAffiliate"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label">
                <label class="label">User Limit</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      type="number"
                      class="input"
                      v-model.number="numberOfUsers"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal my-3">
              <div class="field-label">
                <label class="label">Can only be used in signup?</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <label class="checkbox mainColor--text">
                      <input type="checkbox" v-model="currentCouponCanOnlyBeUsedInSignup" />
                      Check this box if you want this coupon can only be used in signup
                    </label>                    
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal" v-if="currentCouponType == 'Period Free'">
              <div class="field-label">
                <label class="label">Extra credits to give</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      type="number"
                      class="input"
                      v-model.number="currentCouponExtraCreditsToGive"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label">
                <label class="label">Expires at</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <!-- {{ currentCouponExpirationDate }} -->
                    <date-picker v-model="currentCouponExpirationDate"></date-picker>
                  </div>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-label">
                <label class="label">Note</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <textarea
                      class="textarea"
                      placeholder="Please add your note if needed"
                      v-model="currentCouponNote"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>


            <div class="text-right mt-3">
              <v-btn rounded
                color="profileAnchorColor"
                @click="submitCoupon"
                :disabled="!validForm"
                class="button-with-icon text-none whiteColor--text">
                <i class="fa fa-money"></i>
                <span>Submit</span>
              </v-btn>
            </div>
          </div>

          <br />

          
        </div>
        
      </div>
      <div class="d-flex align-center">
        <div>
          <div class="field">
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="perPage" @change="onPerPageChange">
                  <option value="10">10 per page</option>
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="field">
            <div class="control">
              <input type="text" class="input" placeholder="Search" v-model="searchText">
            </div>
          </div>
        </div>
        <div class="pl-3">
          <div class="d-flex flex-col-gap-5">
            <v-btn rounded color="linkColor"
              class="button-with-icon text-none whiteColor--text" @click="onFilter">Search</v-btn>
            <v-btn rounded color="linkColor"
              class="button-with-icon text-none whiteColor--text" @click="exportPage" disabled>Export Page</v-btn>
            <v-btn rounded color="linkColor"
              class="button-with-icon text-none whiteColor--text" @click="exportAndDownloadCsv">
              Export to CSV
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-table
          :data="storeCoupons"
          :loading="isLoading"
          paginated
          backend-pagination
          :total="totalCouponCount"
          :per-page="perPage"
          :current-page="currentPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"

          detail-key="id"
          @details-open="(row, index) => {}">
          <template slot-scope="props">
            <b-table-column field="id" label="Id" sortable numeric>{{ props.row.id }}</b-table-column>
            <b-table-column field="couponType" label="Coupon Type">{{ props.row.couponType }}</b-table-column>
            <b-table-column field="code" label="Code">{{ props.row.code }}</b-table-column>
            <b-table-column field="codeAlias" label="Alias">{{ props.row.codeAlias }}</b-table-column>
            <b-table-column field="couponType" label="Amount">
              {{
                formatAmountForCoupon(props.row)
                
              }}
            </b-table-column>
            <b-table-column field="maxUsers" label="User Limit">{{ props.row.maxUsers }}</b-table-column>
            <b-table-column field="usageCount" label="Usage count">{{ props.row.usageCount }}</b-table-column>
            <b-table-column field="maxUsers" label="Extra Credits">
              {{ props.row.extraCreditsToGive > 0 ? props.row.extraCreditsToGive : ''  }}
            </b-table-column>
            <b-table-column field="product" label="Product">
              {{ serviceLabel(props.row.product) }}
              {{ isOldCoupon(props.row.product) ? " ( OLD, do not use! )" : "" }}
            </b-table-column>
            <b-table-column field="createdAt" label="Created at">{{ toLocalDateString(props.row.createdAt) }}</b-table-column>
            <b-table-column field="expiresAt" label="Expires at">{{ props.row.expiresAt ? toLocalDateString(props.row.expiresAt) : 'Permanant' }}</b-table-column>
            <b-table-column field="creatorEmail" label="Created By">{{ props.row.creatorEmail }}</b-table-column>
            <b-table-column field="note" label="Note">{{ props.row.note }}</b-table-column>
            <b-table-column field="isDeleted" label="Deleted">{{ props.row.isDeleted ? 'Yes' : 'No' }}</b-table-column>
            <!-- expiresAt -->
            <b-table-column label="Action">
              <div class="d-flex gap5" v-if="!props.row.isDeleted">
                <v-btn rounded x-small
                  color="mainColor"
                  class="button-with-icon text-none whiteColor--text"
                  v-clipboard:copy="props.row.code"
                  v-clipboard:success="() => $notify({ group: 'info', type: 'success', text: 'Copied to clipboard' })"
                  v-clipboard:error="() => $notify({ group: 'info', type: 'error', text: 'can not copy to clipboard' })"
                >
                  Copy to clipboard
                </v-btn>
                <v-btn rounded x-small
                  color="linkColor"
                  class="button-with-icon text-none whiteColor--text"
                  @click.stop="editRow(props.row)">
                  Edit
                </v-btn>
                <v-btn rounded x-small
                  color="profileAnchorColor"
                  :disabled="props.row.couponType.toLowerCase() == 'credits'"
                  class="button-with-icon text-none mainColor--text"
                  @click="() => showAddAliasModalAction(props.row)">
                  {{ props.row.codeAlias ? "Edit Alias" : "Add Alias" }}
                </v-btn>
                <v-btn rounded x-small
                  color="redColor"
                  class="button-with-icon text-none whiteColor--text"
                  @click.stop="deleteCoupon(props.row)">
                  Delete
                </v-btn>
              </div>
            </b-table-column>

          </template>
        </b-table>


      </div>

      <div class="modal" :class="{ 'is-active': showErrorUpdatingCouponModal }">
        <div
          class="modal-background"
          @click="
            showErrorUpdatingCouponModal = false;
            errorUpdatingCouponMsg = '';
          "
        ></div>
        <div class="modal-content">
          <div class="box modal-container">
            <p class="subtitle">{{ errorUpdatingCouponMsg }}</p>
            <div class="field has-addons">
              <div class="control">
                <button
                  class="button is-info"
                  @click="
                    showErrorUpdatingCouponModal = false;
                    errorUpdatingCouponMsg = '';
                  "
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" :class="{ 'is-active': showAutoGenModal }">
        <div
          class="modal-background"
          @click="
            showAutoGenModal = false;
            autogenMessage = '';
          "
        ></div>
        <div class="modal-content">
          <div class="box modal-container">
            <p class="subtitle">{{ autogenMessage }}</p>
            <div class="field">
              <label for="autogen-prefix" class="label">Prefix</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  id="autogen-prefix"
                  v-model="autogenPrefix"
                />
              </div>
              <p class="help is-success">Optional</p>
            </div>
            <div class="field">
              <label for="autogen-prefix" class="label">Size</label>
              <div class="control">
                <input
                  type="number"
                  min="10"
                  class="input"
                  id="autogen-prefix"
                  v-model="autogenSize"
                />
              </div>
              <p class="help is-success">Minimum size is 10</p>
            </div>
            <div class="field has-addons">
              <div class="control">
                <button class="button is-info" @click="autogenCouponCode">
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" :class="{ 'is-active': showCodeAliasModal }">
        <div class="modal-background" @click="showCodeAliasModal = false"></div>
        <div class="modal-content">
          <div class="box modal-container">
            <p class="subtitle">Add Alias to Coupon</p>
            <div v-if="editCoupon" class="box">
              <strong>ID </strong> {{ editCoupon.id }} <br />
              <strong>Code: </strong> {{ editCoupon.code }} <br />
              <strong>Product: </strong> {{ serviceLabel(editCoupon.product) }}
              <br />
            </div>
            <div class="field">
              <label for="autogen-prefix" class="label">Coupon Alias</label>
              <div class="control">
                <input
                  type="text"
                  class="input"
                  id="autogen-prefix"
                  v-model="codeAlias"
                />
              </div>
            </div>
            <div class="field has-addons">
              <div class="control">
                <button
                  class="button is-info"
                  @click="() => performAddAliasAction()"
                >
                  Add Alias
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" :class="{ 'is-active': edittingRow }">
        <div class="modal-background" @click="cancelEditting"></div>
        <div class="modal-content">
          <div class="box modal-container">
            <div class="modal-content-root">
              <div class="text-center is-size-5 mb-3 mainColor--text">Edit Coupon</div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Code</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        :disabled="currentCouponType !== 'Credits'"
                        class="input"
                        type="text"
                        v-model="currentCouponCode" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Amount</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        v-if="currentCouponType === 'Credits'"
                        :disabled="currentCouponType !== 'Credits'"
                        class="input"
                        type="number"
                        v-model="currentCouponCredits"
                      />
                      <input
                        v-else-if="currentCouponType === 'Amount Off'"
                        :disabled="currentCouponType !== 'Credits'"
                        class="input"
                        type="number"
                        v-model="currentCouponAmountOff"
                      />
                      <div v-else>
                        -
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">User limit</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        class="input" type="number"
                        v-model="numberOfUsers" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Can only be used in signup</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div class="control">
                        <label class="checkbox mainColor--text">
                          <input type="checkbox" v-model="currentCouponCanOnlyBeUsedInSignup" />
                          Check this box if you want this coupon can only be used in signup
                        </label>                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Extra credits to give</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input
                        class="input" type="number"
                        v-model="currentCouponExtraCreditsToGive" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label">
                  <label class="label">Expires at</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <date-picker v-model="currentCouponExpirationDate"></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Note</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <textarea class="textarea edit-note-textarea" v-model="currentCouponNote"></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <v-btn rounded
                  color="profileAnchorColor"
                  class="button-with-icon text-none mainColor--text"
                  @click="updateCoupon()">
                  Save
                </v-btn>
                <v-btn rounded
                  color="grayColor"
                  class="button-with-icon text-none mainColor--text ml-2"
                  @click="cancelEditting">
                  Cancel
                </v-btn>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from 'moment'
import Multiselect from "vue-multiselect";
import DatePicker from 'vue2-datepicker';

import { mixin as ServiceMixin } from "@/components/service_mixin";
import {
  DEFAULT_PREFIX,
  oldPlans,
  PRICE_OF_EACH_VERSIONING,
} from "@/components/plans_constants"
import { toLocalDateString } from "@/utils";


function download_csv(csv, filename) {
  var csvFile;
  var downloadLink;

  csvFile = new Blob([csv], { type: "text/csv" });
  downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
}

function export_table_to_csv(html, filename) {
  var csv = [];
  var rows = document.querySelectorAll("table tr");

  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");

    for (var j = 0; j < cols.length - 1; j++) row.push(cols[j].innerText);

    csv.push(row.join(","));
  }
  download_csv(csv.join("\n"), filename);
}
export default {
  components: {
    Multiselect,
    DatePicker
  },
  mixins: [ServiceMixin],
  data () {
    return {
      perPage: 50,
      currentPage: 1,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      searchText: '',
      isLoading: false,
      totalCouponCount: 0,

      edittingRow: '',
      currentCouponCode: "",
      currentCouponCredits: 0,
      currentCouponAmountOff: 0,
      currentCouponFreeDays: 0,
      currentCouponProduct: "SERVICE_PKG_A",
      currentCouponType: "Credits",
      currentCouponAffiliates: [],
      currentCouponNote: '',
      currentCouponExpirationDate: moment().add(1, 'M').toDate(),
      currentCouponExtraCreditsToGive: 0,
      currentCouponCanOnlyBeUsedInSignup: false,

      couponCodeErrMsg: "",
      numberOfUsers: 0,
      showErrorUpdatingCouponModal: false,
      errorUpdatingCouponMsg: "",
      affiliates: [],
      autogenMessage: "",
      showAutoGenModal: false,
      autogenSize: 10,
      autogenPrefix: "",

      editCoupon: null,
      showCodeAliasModal: false,
      codeAlias: ""
    };
  },
  watch: {
    currentCouponType: function(newValue) {
      this.currentCouponProduct = ''
      this.currentCouponAmountOff = 0;
      if (newValue === "Credits") {
        this.currentCouponFreeDays = 0;
      } else if (newValue === "Amount Off") {
        this.currentCouponCredits = 0;
        this.currentCouponFreeDays = 0;
      } else if (newValue === "Period Free") {
        this.currentCouponCredits = 0;
      } else if (newValue === 'Credit Percentage Off') {
        this.currentCouponCredits = 0;
      }
    }
  },
  computed: {
    DEFAULT_PREFIX () {
      return DEFAULT_PREFIX
    },
    PRICE_OF_EACH_VERSIONING () {
      return PRICE_OF_EACH_VERSIONING
    },
    currentUser() {
      if (this.$store.state.user) {
        return this.$store.state.user;
      }
      return {};
    },
    storeCoupons() {
      return this.$store.state.coupons;
    },
    validForm() {
      if (this.currentCouponCode && this.numberOfUsers) {
        if (this.currentCouponType === "Credits") {
          if (this.currentCouponCredits && this.currentCouponCredits > 0) {
            return true;
          }
        } else if (this.currentCouponType === "Amount Off") {
          if (
            this.currentCouponProduct &&
            this.currentCouponAmountOff &&
            this.currentCouponAmountOff > 0
          ) {
            return true;
          }
        } else if (this.currentCouponType === "Period Free") {
          if (
            this.currentCouponProduct &&
            this.currentCouponFreeDays &&
            this.currentCouponFreeDays > 0
          ) {
            return true;
          }
        } else if (this.currentCouponType === 'Percent Off') {
          if (this.currentCouponProduct && this.currentCouponAmountOff > 0 && this.currentCouponAmountOff <= 100) {
            return true;
          }
        } else if (this.currentCouponType === 'Credit Percentage Off' ) {
          if (this.currentCouponAmountOff > 0 && this.currentCouponAmountOff <= 100) {
            return true;
          }
        }
      }
      return false;
    }
  },
  methods: {
    formatAmountForCoupon(c) {
      if (c.couponType === "Credits") {
        return c.credits
      } else if (c.couponType === "Period Free") {
        return `${c.freeDays} days free`
      } else if (c.couponType === 'Credit Percentage Off') {
        return `${c.amountOff}% Off`
      } else if (c.couponType === 'Percent Off') {
        return `${c.amountOff}% Off`
      } else {
        return c.amountOff
      }
    },
    isOldCoupon(product) {
      // SERVICE_PKG_B is only old in the prev
      // now all plans which are not starting with P1__
      if (!product) {
        return false;
      }
      if (product == 'platinum_free') { // because this could be a perido coupon
        return false;
      }
      if (oldPlans.includes(product)) {
        return true;
      }
      return false;
    },
    exportPage () {
      console.log('Export page function')
    },
    exportCsv() {
      const now = new Date();
      this.html = document.querySelector("table").outerHTML;
      export_table_to_csv(
        this.html,
        "Coupon_" +
          now.getUTCFullYear() +
          "_" +
          (now.getUTCMonth() + 1) +
          "_" +
          now.getUTCDate() +
          ".csv"
      );
    },
    exportAndDownloadCsv() {
      const coupons = this.$store.state.coupons;
      if (!coupons || coupons.length === 0) {
        return;
      }
      let csv = `Id,Coupon Type,Code,Alias,Amount,User Limit,Usage count,\
        Extra Credits,Product,Created at,Expires at,Created by,Note,Deleted,Usage Emails\n`;
      for (let i = 0; i < coupons.length; i++) {
        let productName = this.serviceLabel(coupons[i].product)
        csv += `${coupons[i].id},`;
        csv += `${coupons[i].couponType},`;
        csv += `${coupons[i].code},`;
        csv += `${coupons[i].codeAlias || ""},`;
        csv += `${this.formatAmountForCoupon(coupons[i])},`;
        csv += `${coupons[i].maxUsers},`;
        csv += `${coupons[i].usageCount},`;
        csv += `${coupons[i].extraCreditsToGive || ""},`;
        csv += `${productName || ""},`;
        csv += `${coupons[i].createdAt},`;
        csv += `${coupons[i].expiresAt || "Permanent"},`;
        csv += `${coupons[i].creatorEmail || ""}, `;
        csv += `${coupons[i].note || ""},`;
        csv += `${coupons[i].isDeleted ? "Yes" : "No"},`;
        csv += `${coupons[i].usageEmails || ""}`;
        csv += "\n";
      }
      const timestamp = moment().format("YYYY_M_D_h_mm_ss");
      const fileName = `Coupon_${timestamp}.csv`;
      download_csv(csv, fileName);
    },
    editRow(coupon) {
      this.edittingRow = coupon.id;
      this.currentCouponCode = coupon.code;
      this.currentCouponCredits = coupon.credits;
      this.currentCouponAmountOff = coupon.amountOff;
      this.currentCouponFreeDays = coupon.freeDays;
      this.currentCouponType = coupon.couponType;
      this.numberOfUsers = coupon.maxUsers;
      this.currentCouponExtraCreditsToGive = coupon.extraCreditsToGive;
      this.currentCouponNote = coupon.note;
      this.currentCouponExpirationDate = coupon.expiresAt ? moment(coupon.expiresAt).toDate() : moment(coupon.expiresAt).add(1, 'M').toDate()
      this.currentCouponCanOnlyBeUsedInSignup = coupon.canOnlyBeUsedInSignup
      if (coupon.couponType !== "credits") {
        this.currentCouponProduct = coupon.product;
      }
    },
    showAddAliasModalAction(coupon) {
      this.codeAlias = coupon.codeAlias || "";
      this.editCoupon = coupon;
      this.showCodeAliasModal = true;
    },
    performAddAliasAction() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("addAliasToCouponForAdmin", {
          couponId: this.editCoupon.id,
          codeAlias: this.codeAlias
        })
        .then(response => {
          if (response.data && response.data.status == "SUCCESS") {
            this.$notify({
              group: 'info', type: 'success',
              text: "Successfully add code alias to coupon."
            });
            this.loadData();
            this.showCodeAliasModal = false;
          } else {
            this.$notify({
              group: 'info', type: 'error',
              text: response.data.error
            });
          }
        })
        .finally(() => {
          this.$store.commit("hideLoading");
        });
    },
    setCurrentCouponAffiliate(affiliate) {
      this.currentCouponAffiliates.push(affiliate);
    },
    deleteCoupon(coupon) {
      if (!window.confirm("Do you want to delete this coupon?")) {
        return;
      }
      this.$store
        .dispatch("deleteCouponForAdmin", {
          id: coupon.id
        })
        .then(() => this.loadData())
        .catch(err => {
          console.log("Error deleting coupon", err);
          this.couponCodeErrMsg = "Can not delete the coupon";
        })
        .finally(() => this.cancelEditting());
    },
    cancelEditting() {
      this.edittingRow = "";
      this.currentCouponCode = "";
      this.currentCouponCredits = 0;
      this.currentCouponAmountOff = 0;
      this.currentCouponFreeDays = 0;
      this.currentCouponType = "Credits";
      this.numberOfUsers = 0;
      this.currentCouponExtraCreditsToGive = 0;
      this.currentCouponProduct = "";
      this.currentCouponAffiliates = [];
      this.currentCouponNote = '';
      this.currentCouponExpirationDate = moment().add(1, 'M').toDate()
      this.currentCouponCanOnlyBeUsedInSignup = false;
    },
    updateCoupon() {
      this.$store.commit("showLoading");
      this.$store
        .dispatch("updateCouponForAdmin", {
          id: this.edittingRow,
          code: this.currentCouponCode,
          credits: this.currentCouponCredits,
          numberOfUsers: this.numberOfUsers,
          note: this.currentCouponNote,
          expirationDate: this.currentCouponExpirationDate.valueOf(),
          extraCreditsToGive: this.currentCouponExtraCreditsToGive,
          canOnlyBeUsedInSignup: this.currentCouponCanOnlyBeUsedInSignup ? 1 : 0,
        })
        .then(response => {
          if (response.data.status === "FAILURE") {
            this.showErrorUpdatingCouponModal = true;
            this.errorUpdatingCouponMsg = response.data.errMsg;
            return 1;
          } else {
            return this.loadData();
          }
        })
        .catch(err => {
          console.log("Error creating new coupon", err);
          this.showErrorUpdatingCouponModal = true;
          this.errorUpdatingCouponMsg = "Error updating coupon";
        })
        .finally(() => {
          this.cancelEditting();
          this.$store.commit("hideLoading");
        });
    },
    autogenCouponCode() {
      if (this.autogenSize < 10) {
        this.autogenMessage = "Coupons must be at least 10 characters long";
        return;
      }
      this.$store
        .dispatch("autoGenCouponCode", {
          prefix: this.autogenPrefix,
          size: parseInt(this.autogenSize)
        })
        .then(response => {
          if (response.data.status == "FAILURE") {
            this.autogenMessage = response.data.errorMsg;
          } else {
            this.currentCouponCode = response.data.couponCode;
            this.showAutoGenModal = false;
          }
          return 1;
        });
    },
    submitCoupon() {
      let exists = this.storeCoupons
        .map(c => c.code.toLowerCase())
        .includes(this.currentCouponCode.toLowerCase());
      if (exists) {
        // TODO: add a modal stating that this is not a unique coupon code
        return;
      }
      let data = {
        couponType: this.currentCouponType,
        code: this.currentCouponCode,
        credits: this.currentCouponCredits,
        amountOff: this.currentCouponAmountOff,
        freeDays: this.currentCouponFreeDays,
        numberOfUsers: this.numberOfUsers,
        product: this.currentCouponProduct,
        affiliates: this.currentCouponType === "Amount Off" ? this.currentCouponAffiliates : [],
        note: this.currentCouponNote,
        expirationDate: this.currentCouponExpirationDate.valueOf(),
        extraCreditsToGive: this.currentCouponExtraCreditsToGive, // this is only for period free coupon.
        canOnlyBeUsedInSignup: this.currentCouponCanOnlyBeUsedInSignup ? 1 : 0,
      }
      this.$store.commit("showLoading");
      this.$store
        .dispatch("makeCouponForAdmin", data)
        .then(response => {
          if (response.data.status === "FAILURE") {
            this.showErrorUpdatingCouponModal = true;
            this.errorUpdatingCouponMsg = response.data.errMsg;
            return 1;
          } else {
            return this.loadData();
          }
        })
        .catch(err => {
          console.log("Error creating new coupon", err);
          this.showErrorUpdatingCouponModal = true;
          this.errorUpdatingCouponMsg = "Error saving coupon";
        })
        .finally(() => {
          this.cancelEditting();
          this.$store.commit("hideLoading");
        });
    },
    onPerPageChange () {
      this.$nextTick(() => this.loadData())
    },
    onPageChange (page) {
      this.currentPage = page
      this.loadData()
    },
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.loadData()
    },
    onFilter () {
      // console.log('filtering')
      this.$nextTick(() => {
        this.loadData()
      })
    },
    loadData() {
      const params = [
          `sortField=${this.sortField}`,
          `sortOrder=${this.sortOrder}`,
          `page=${this.currentPage}`,
          `perPage=${this.perPage}`,
          `filterText=${this.searchText}`,
          `onlyWatchdogRuns=${1}`
        ].join('&')
      
      this.isLoading = true;

      return this.$store
        .dispatch("loadCouponsForAdmin", { params })
        .then(response => {
          this.totalCouponCount = response.data.total;
          this.affiliates = response.data.affiliates;
          this.$store.commit("setCoupons", { coupons: response.data.coupons });
          return 1;
        })
        .catch(err => {
          console.log("Error fetching admin users ", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toLocalDateString (d) {
      return toLocalDateString(d)
    }
  },
  beforeMount() {
    return this.loadData();
  }
};
</script>

<style lang="scss">
// .edit-note-textarea {
//   &:focus {
//     position: fixed;
//     top: 20%;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 80vw !important;
//     z-index: 100;
//   }
// }
</style>
