<template>
  <div
    :class="['flex flex-column site-page-card silo-card mb-8 p-5', !isCardInEditMode?'cursor-pointer':'']"
    @click="!isCardInEditMode ? onSiloClick() : undefined"
  >
    <div class="silo-card-head">
      <div
        class="silo-card-title"
      >
        <div class="project-name">
          <span
            v-if="!isCardInEditMode"
            style="cursor:text;"
            @click.stop="editedName = silo.name; isCardInEditMode = true;"
          >
            {{ silo.name }}
          </span>
          <v-text-field
            v-else
            autofocus
            outlined
            v-model="editedName"
            @blur="saveEditName()"
            @keypress.enter="saveEditName()"
            dense
            hide-details
            style="max-width: max-content; background-color: white"
          >
          </v-text-field>
        </div>
        <div class="count-label">
          {{ silo.silo_keywords?.length || 0 }}
        </div>
      </div>

      <div
        class="is-size-6 title-font mainColor--text"
        style="margin-top: -3px;"
      >
        <v-btn icon :id="`dot-menu-for-${silo.id}`">
          <span
            class="material-icons-outlined mainColor--text"
            style="font-size: 30px"
            >more_horiz</span
          >
        </v-btn>
        <v-menu :activator="`#dot-menu-for-${silo.id}`" bottom offset-y>
          <v-list>
            <template v-if="isProjectLevelView">
              <v-list-item
                v-if="!silo.pageId"
                @click="showConnectToPageModal = true"
              >
                <v-list-item-title class="mainColor--text">
                  Connect to page
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-else
                @click="updateSilo({pageId: null}, true)"
              >
                <v-list-item-title
                  class="mainColor--text"
                >
                  Disconnect from page<br/>
                  <small class="grayColor--text text--darken-4">{{ silo?.page?.name }}</small>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item @click="showDeleteConfirmModal = true">
              <v-list-item-title class="mainColor--text">
                Delete
              </v-list-item-title>
            </v-list-item>
            <template v-if="isWhiteGloveUser && isMissionControlSession">
              <v-list-item
                v-if="silo.content_report_order.fulfillement_status !== 'pending'"
                @click="updateOrder({set_pending: true})"
              >
                <v-list-item-title class="mainColor--text">
                  Mark as {{ getFulfillementStatusLabel('pending')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="silo.content_report_order.fulfillement_status !== 'in_progress'"
                @click="updateOrder({set_in_progress: true})"
              >
                <v-list-item-title class="mainColor--text">
                  Mark as {{ getFulfillementStatusLabel('in_progress')}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="silo.content_report_order.fulfillement_status !== 'completed'"
                @click="updateOrder({set_completed: true})"
              >
                <v-list-item-title class="mainColor--text">
                  Mark as {{ getFulfillementStatusLabel('completed')}}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mt-1 mainColor--text font-weight-regular">
      Updated {{ showElaspedTime(silo.updatedAt) }}
    </div>
    <footer class="footer-container">
      <div
        v-if="['in_cart', 'in_cart_checkout'].includes(silo?.content_report_order?.order_status)"
        class="footer-item"
      >
        <span class="material-icons-outlined">shopping_cart</span>
        <span class="price-on-checkout">Order in cart</span>
      </div>
      <div
        v-if="silo?.content_report_order?.order_status === 'paid' && silo?.content_report_order?.price_on_checkout"
        class="footer-item footer-item-price-on-checkout"
      >
        <span class="material-icons-outlined">receipt_long</span>
        <v-menu
          open-on-hover
          :nudge-width="250"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              class="material-icons"
              style="color: #B3C3BA;"
              v-bind="attrs"
              v-on="on"
            >info</span>
          </template>

          <v-card class="p-4 mainColor--text">
            <div class="font-weight-bold mb-4" style="font-size: 1rem;">You have ordered {{ silo.content_report_order?.items_on_checkout?.map(item => item.quantity)?.reduce((a, b) => a + b, 0) }} reports for ${{ silo.content_report_order.price_on_checkout }}</div>
            <div v-for="item, index in silo.content_report_order?.items_on_checkout || []" :key="index">
              <template v-if="item.type === 'top_level'">
                <div class="font-weight-bold mb-1">1 report for the Top-level keyword</div>
                <div>{{ item.report_label }}</div>
                <div class="mb-3">(${{ item.unit_amount }} USD)</div>
              </template>
              <template v-else-if="item.type === 'supporting'">
                <div class="font-weight-bold mb-1">{{ item.quantity }} reports for Supporting keywords</div>
                <div>{{ item.report_label }}</div>
                <div>({{ item.quantity }}x ${{ item.unit_amount }} USD)</div>
              </template>
            </div>
          </v-card>
        </v-menu>

        <span class="price-on-checkout">${{ silo.content_report_order.price_on_checkout }}</span>
      </div>
      <div
        v-if="['paid', 'to_be_paid_by_invoice'].includes(silo?.content_report_order?.order_status) && silo?.content_report_order?.due_date"
        class="footer-item tooltip"
        data-tooltip="Due date"
      >
        <span class="material-icons-outlined">alarm_on</span>
        <span>{{ showDate(silo.content_report_order.due_date) }}</span>
      </div>
    </footer>

    <div class="modal" :class="{ 'is-active': !!showDeleteConfirmModal }">
      <div class="modal-background" @click.stop="showDeleteConfirmModal = false"></div>
      <div class="modal-content">
        <div class="box modal-container" style="overflow: hidden">
          <div class="modal-content-root">
            <div class="title-font is-size-4 mainColor--text">
              You’re about to delete this silo
            </div>
            <div class="control flex mt20">
              <div class="flex-grow-1"></div>
              <v-btn
                rounded
                outlined
                color="mainColor"
                class="button-with-icon mainColor--text text-none"
                @click.stop="showDeleteConfirmModal = false"
              >
                <span>Cancel</span>
              </v-btn>
              <v-btn
                rounded
                color="profileAnchorColor"
                class="button-with-icon text-none whiteColor--text"
                @click.stop="deleteSilo"
              >
                <i class="fa fa-trash mr5"></i>
                <span>Delete</span>
              </v-btn>
            </div>
          </div>
        </div>
        <button
          class="custom-modal-close-btn"
          aria-label="close"
          @click.stop="showDeleteConfirmModal = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
    </div>

    <connect-silo-to-page-modal
      v-if="showConnectToPageModal"
      :silo="silo"
      @close="showConnectToPageModal = false"
      @submitted="showConnectToPageModal = false; refreshData()"
    />

  </div>
</template>

<script>
import moment from 'moment';
import { getPureServicePkgName } from '@/utils';
import { whiteGlovePlans } from "@/components/plans_constants"
import { getFulfillementStatusLabel } from './utils'
import ConnectSiloToPageModal from './ConnectSiloToPageModal.vue';

export default {
  components: { ConnectSiloToPageModal },
  props: ['silo', 'isProjectLevelView', 'refreshData'],
  data() {
    return {
      isCardInEditMode: false,
      editedName: '',
      showDeleteConfirmModal: false,
      showConnectToPageModal: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isWhiteGloveUser () {
      if (!this.user) return false;

      const accountType = this.$store.state.selectedSubAccountRelation
        ?this.$store.state.selectedSubAccountRelation.ownerAccountType
        :this.user.accountType

      if (whiteGlovePlans.includes(getPureServicePkgName(accountType))) {
        return true;
      }
      return false;
    },
    isMissionControlSession () {
      return this.$store.state.selectedSubAccountRelation?.isMissionControlSubAccount
    },
  },
  methods: {
    getFulfillementStatusLabel: getFulfillementStatusLabel,
    showElaspedTime(time) {
      return moment(time).fromNow();
    },
    showDate(time) {
      return moment(time).format("MMM DD");
    },
    onSiloClick() {
      this.$router.push(
        `/sites/${this.silo.siteId}/campaign/${this.silo.campaignId}/silo?id=${this.silo.id}`,
      );
    },
    saveEditName() {
      if (this.editedName === this.silo.name) {
        // wait a little in case save is too fast
        // (this fix is needed to prevent navigating to silo when save is triggered by clicking on the silo card)
        return new Promise(r => setTimeout(r, 300))  
          .then(() => {
            this.isCardInEditMode = false;
          }) 
      }
  
      this.updateSilo(
        { name: this.editedName }
      )
        .then(() => {
          this.silo.name = this.editedName;
          return Promise.resolve()
        })
        .then(() => {
          this.$notify({
            group: 'info',
            type: 'success',
            text: 'Successfully updated silo.',
          });
  
          // wait a little in case save is too fast
          // (this fix is needed to prevent navigating to silo when save is triggered by clicking on the silo card)
          return new Promise(r => setTimeout(r, 300))  
            .then(() => {this.isCardInEditMode = false;// this.editableSiteId = null;
          })
        })
    },
    updateSilo(payload, refresh = false) {
      this.$store.commit('showLoading');
      return this.$store
        .dispatch('updateSilo', {
          siloId: this.silo.id,
          body: payload,
        })
        .then(() => {
          if (refresh) {
            this.refreshData();
          }
          return Promise.resolve()
        })
        .finally(() => {
          this.$store.commit('hideLoading');
        });
    },
    deleteSilo() {
      this.$store.commit('showLoading');
      this.$store
        .dispatch('deleteSilo', this.silo.id)
        .then(() => {
          this.$notify({
            group: 'info',
            type: 'success',
            text: 'Successfully deleted silo.',
          });
          this.showDeleteConfirmModal = false;
          this.refreshData();
        });
    },
    updateOrder(payload) {
      this.$store.commit('showLoading');
      this.$store.dispatch('updateSiloContentReportOrder', {
        siloId: this.silo.id,
        body: payload
      })
      .then(response => {
        if (response.data && response.data.status == 'SUCCESS') {
          this.refreshData()
        }
      })
      .finally(() => {
        this.$store.commit('hideLoading');
      })
    },
  },
};

</script>

<style lang="scss" scoped>
@import './silo.scss';
</style>
